import React from "react";

function JoinCompany() {
  return (
    <div className="optimization-bg">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-10 col-md-12 col-sm-12 mt-5 mb-5">
            <h1 className="Text-heading text-center" data-aos="fade-up">
              Premier Chatbot Pioneers
            </h1>
            <p className="text-detail mt-2 text-center" data-aos="fade-up">
              Virtual Tech can assist you in developing powerful chatbots. We
              can provide a solution for any platform or industry.
            </p>
            <div className="text-center mt-5" data-aos="fade-up">
              <button className="btn btn-experts p-3">
                Have A Free Consultation Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinCompany;
