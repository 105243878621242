import React from "react";
import Line from "../../assets/Image/UnderLine-01.svg";

function Celebrate() {
  return (
    <div className="celebrate-bg">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5" data-aos="fade-up">
            <div className="row justify-content-around align-items-center card-metaverse2">
              <div className="col-lg-5 col-md-12 card-border about_left_col_wrap mt-5">
                <div className="mt-4 mb-4">
                  <h1 className="text-center Text-heading mb-1">170+</h1>
                  <p className="text-center text-detail mt-1">
                    Employees spread worldwide
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 card-border about_left_col_wrap mt-5 ">
                <div className="mt-4 mb-4">
                  <h1 className="text-center Text-heading mb-1">150+</h1>
                  <p className="text-center text-detail mt-1">
                    Globally satisfied clients
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 card-border about_left_col_wrap mt-5">
                <div className="mt-4 mb-4">
                  <h1 className="text-center Text-heading mb-1">20+</h1>
                  <p className="text-center text-detail mt-1">
                    Countries with our persence
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 card-border about_left_col_wrap mt-5">
                <div className="mt-4 mb-4">
                  <h1 className="text-center Text-heading mb-1">25M+</h1>
                  <p className="text-center text-detail mt-1">
                    Download Clicks On Our Apps
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5" data-aos="fade-up">
            <div>
              <h2 className="Text-heading stak-sd" data-aos="fade-up">Celebrating Change</h2>
              <div className="mt-1" data-aos="fade-up">
                <img src={Line} className="img-fluid stak-sd " width={"50%"} />
              </div>
              <p className="text-detail text-left" data-aos="fade-up">
                Embracing the power of virtual technologies, Virtual Tech is
                committed to staying at the forefront of digital
                transformations. With our cutting-edge tech stack, we have
                empowered numerous enterprises and startups to reach new heights
                in their business ventures. Join forces with our skilled team
                members and embark on a journey to uncover the untapped
                potential of your business in the virtual realm. Don't let
                anything hold you back, seize the virtual revolution with
                Virtual Tech today.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Celebrate;
