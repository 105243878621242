import React from "react";

const data = [
  {
    title: "Creating native apps",
    detail:
      "Our skilled mobile app development services   will create applications for both iOS and mobile Android app development based on your company's needs.",
  },
  {
    title: "Hybrid app creation",
    detail:
      "With a unique combination of web and native app technologies. We will design cross-platform apps that can work in many situations.",
  },
  {
    title: "Development of Progressive Web Apps",
    detail:
      "Our team can create a progressive app with a single codebase that can reach a large range of your potential clients on any device.",
  },
];
function ChooseThe() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5">
          <h1 className="text-center Text-heading" data-aos="fade-up">
           Mobile App Success Amplified
          </h1>
          <p className="text-center text-detail mt-2" data-aos="fade-up">
            We have been providing top-tier IT solutions for the last 6+ years
            and can offer cost-effective mobile app development services with assured ROI enhancement.
          </p>
        </div>
        <div className="row justify-content-around align-items-center">
          {data.map((data, index) => {
            return (
              <>
                <div
                  key={index}
                  className="col-lg-3 col-md-5 col-sm-12 border-gradient mt-5"
                  style={{ height: "200px" }}
                  data-aos="flip-right"
                  data-aos-delay="1000"
                >
                  <div className="m-3">
                    <h4 className="text-title">{data.title}</h4>
                    <p className=" text-detail mt-3">{data.detail}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ChooseThe;
