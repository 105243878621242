import React from 'react';
import images from '../../assets/logo/virtualLogo-01.png';
import "./navbar.css";
import { CgMenuLeft } from 'react-icons/cg';
import { AiOutlineClose } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
function NavbaaComp() {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <NavLink to="/">
        <img src={images} alt="app__logo" />
        </NavLink>
      </div>
      <ul className="app__navbar-links mt-lg-2">
      <NavLink to="/">
              <li><a href="#home">Home</a></li>
              </NavLink>
              <NavLink to="web3">
              <li className="p__opensans"><a href="#about">Web3</a></li>
              </NavLink>
              <NavLink to="metaverse">
              <li className="p__opensans"><a href="#menu">Metaverse</a></li>
              </NavLink>
              <NavLink to="app">
              <li className="p__opensans"><a href="#awards">App</a></li>
              </NavLink>
              <NavLink to="software">
              <li className="p__opensans"><a href="#contact">Software</a></li>
              </NavLink>
              <NavLink to="ai">
              <li className="p__opensans"><a href="#contact">Ai</a></li>
              </NavLink>
              <NavLink to="iot">
              <li className="p__opensans"><a href="#contact">Iot</a></li>
              </NavLink>
              <NavLink to="chatbot">
              <li className="p__opensans"><a href="#contact">Chatbot</a></li>
              </NavLink>
              <NavLink to="portfolio">
              <li className="p__opensans"><a href="#contact">Portfolio</a></li>
              </NavLink>
              <NavLink to="aboutus">
              <li className="p__opensans"><a href="#contact">About Us</a></li>
              </NavLink>
              <NavLink to="contactus">
              <li className="p__opensans"><a href="#contact">Contact Us</a></li>
              </NavLink>
      </ul>
      <div className="app__navbar-smallscreen">
        <CgMenuLeft color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} className='mob_icon'/>
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <AiOutlineClose fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
            <NavLink to="/">
              <li><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
              </NavLink>
              <NavLink to="web3">
              <li className="p__opensans"><a href="#about" onClick={() => setToggleMenu(false)}>Web3</a></li>
              </NavLink>
              <NavLink to="metaverse">
              <li className="p__opensans"><a href="#menu" onClick={() => setToggleMenu(false)}>Metaverse</a></li>
              </NavLink>
              <NavLink to="app">
              <li className="p__opensans"><a href="#awards" onClick={() => setToggleMenu(false)}>App</a></li>
              </NavLink>
              <NavLink to="software">
              <li className="p__opensans"><a href="#contact" onClick={() => setToggleMenu(false)}>Software</a></li>
              </NavLink>
              <NavLink to="ai">
              <li className="p__opensans"><a href="#contact" onClick={() => setToggleMenu(false)}>Ai</a></li>
              </NavLink>
              <NavLink to="iot">
              <li className="p__opensans"><a href="#contact" onClick={() => setToggleMenu(false)}>Iot</a></li>
              </NavLink>
              <NavLink to="chatbot">
              <li className="p__opensans"><a href="#contact" onClick={() => setToggleMenu(false)}>Chatbot</a></li>
              </NavLink>
              <NavLink to="portfolio">
              <li className="p__opensans"><a href="#contact" onClick={() => setToggleMenu(false)}>Portfolio</a></li>
              </NavLink>
              <NavLink to="aboutus">
              <li className="p__opensans"><a href="#contact" onClick={() => setToggleMenu(false)}>About Us</a></li>
              </NavLink>
              <NavLink to="contactus">
              <li className="p__opensans"><a href="#contact" onClick={() => setToggleMenu(false)}>Contact Us</a></li>
              </NavLink>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
}

export default NavbaaComp;
