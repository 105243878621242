import React from "react";
import "./privacy.css";

function Privacy() {
  return (
    <>
      <div className="container mt-5">
        <div className="row justify-content-center mt-5">
          <div className="col-md-12 mt-5 text-white">
            <h1 className="privacy-heading">Privacy Policy for Virtual Cops</h1>
            <p className="text-white">
              At virtual cops, one of our main priorities is the privacy of our
              visitors. This Privacy Policy document contains types of
              information that is collected and recorded by virtual cops and how
              we use it.
            </p>
            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>
            <h1>Log Files</h1>
            <p>
              Virtual cops follows a standard procedure of using log files.
              These files log visitors when they use app. The information
              collected by log files include internet protocol (IP) addresses,
              browser type, Internet Service Provider (ISP), date and time
              stamp, referring/exit pages, and possibly the number of clicks.
              These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the app, tracking users' movement on the
              app, and gathering demographic information.
            </p>
            <h1>Our Advertising Partners</h1>
            <p>
              Some of advertisers in our app may use cookies and web beacons.
              Our advertising partners are listed below. Each of our advertising
              partners has their own Privacy Policy for their policies on user
              data. For easier access, we hyperlinked to their Privacy Policies
              below.
            </p>
            <h1>Privacy Policies</h1>
            <p>
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of virtual cops. Third-party ad servers
              or ad networks uses technologies like cookies, JavaScript, or
              Beacons that are used in their respective advertisements and links
              that appear on virtual cops. They automatically receive your IP
              address when this occurs. These technologies are used to measure
              the effectiveness of their advertising campaigns and/or to
              personalize the advertising content that you see on this app or
              other apps or websites.
              <p>
                Note that virtual cops has no access to or control over these
                cookies that are used by third-party advertisers.
              </p>
            </p>
            <h1>Third Party Privacy Policies</h1>
            <p>
              Virtual cops Privacy Policy does not apply to other advertisers or
              websites. Thus, we are advising you to consult the respective
              Privacy Policies of these third-party ad servers for more detailed
              information. It may include their practices and instructions about
              how to opt-out of certain options.
            </p>
            <h1>Children's Information</h1>
            <p>
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </p>
            <p>
              Virtual cops does not knowingly collect any Personal Identifiable
              Information from children under the age of 13. If you think that
              your child provided this kind of information on our App, we
              strongly encourage you to contact us immediately and we will do
              our best efforts to promptly remove such information from our
              records.
            </p>
            <h1>Online Privacy Policy Only</h1>
            <p>
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our App with regards to the information that
              they shared and/or collect in blackmetal. This policy is not
              applicable to any information collected offline or via channels
              other than this app. Our Privacy Policy was created with the help
              of the App Privacy Policy Generator from App-Privacy-Policy.com
            </p>
            <h1>Consent</h1>
            <p>
              By using our app, you hereby consent to our Privacy Policy and
              agree to its Terms and Conditions.
            </p>
            <h6>Generated using App Privacy Policy Generator</h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
