import React from "react";

import chatbot1 from "../../assets/Icon/chatbot1-01.svg";
import chatbot2 from "../../assets/Icon/chatbot2-01.svg";
import chatbot3 from "../../assets/Icon/chatbot3-01.svg";
import chatbot4 from "../../assets/Icon/chatbot4-01.svg";
import chatbot5 from "../../assets/Icon/chatbot5-01.svg";

import ImageSix from "../../assets/create market/etherum.png";
import ImageSeven from "../../assets/create market/smartContract.png";
import ImageEight from "../../assets/create market/cloud.png";
import ImageNine from "../../assets/create market/blockchain-security.png";

const data = [
  {
    image: chatbot1,
    title: "Proven Architecture",
    description:
      "We have built several advanced chatbots before knowing the exact combinations of APIs, plugins and frameworks.",
  },
  {
    image: chatbot2,
    title: "Interactive Design",
    description:
      "Our dedicated UI/UX designers are   top chatbot development company creating an amazing app interface.",
  },
  {
    image: chatbot5,
    title: "Diverse Domain ",
    description:
      "We work on diverse domains and serve E-commerce businesses, healthcare, the education sector and many more.",
  },
  {
    image: chatbot3,
    title: "Different Typologies ",
    description:
      "Our chatbots are fixed with NLP, ML and many other cutting-edge tech solutions. Which will increase business sales.",
  },
  {
    image: chatbot4,
    title: "Custom Bot ",
    description:
      "Development Virtual Tech will offer you chatbot development solutions for various services like feedback, customer care, booking and payments.",
  },
  {
    image: ImageSix,
    title: "Customer Insights",
    description:
      " Get regular peeps into your customer insights through our efficient chatbot services and get high customer reach and engagement.",
  },
  {
    image: ImageSeven,
    title: "Seamless Services ",
    description:
      "Our seamless chatbot development services are inclined towards taking every customer query as top priority and solving them by offering the best solutions.",
  },
  {
    image: ImageEight,
    title: "Customer Engagement",
    description:
      "Our chatbot development company in   always stays ahead of its competitors, as we are focused on increasing customer engagement.",
  },
  {
    image: ImageNine,
    title: "Improved Business ",
    description:
      "Reach out to more customers on a daily basis with our efficient chatbot development services that help you increase your valuable customer base.",
  },
];
function Advantage() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5 mb-5">
        <div className="col-lg-10 col-md-12 col-sm-12">
          <h1 className="Text-heading text-center" data-aos="fade-up">
            SEO-Optimized Chatbot Development Services
          </h1>
          <p className="text-detail  text-center" data-aos="fade-up">
            Virtual Tech is an efficient chatbot development company with
            seasoned software developers with the right AI knowledge to build
            world-class chatbots.
          </p>
        </div>
        <div className="row d-flex justify-content-between mobile-responsive ">
          {data.map((data, index) => {
            return (
              <>
                <div
                  key={index}
                  className="col-lg-3 col-md-5 col-sm-12 border-gradient border-gradient-purple mt-3 mb-3"
                  data-aos="flip-up"
                >
                  <div className="row justify-content-center ">
                    <div className="col-md-10 mt-3 mb-3">
                      {/* <div className="text-center">
                        <img
                          src={data.image}
                          className="img-fluid"
                          width={"130px"}
                          alt=""
                        />
                      </div> */}
                      <div className="mt-3">
                        <h6 className="text-title mb-3">
                          <strong>{data.title}</strong>
                        </h6>
                        <p className="mt-2 text-description">
                          {data.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Advantage;
