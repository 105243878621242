import React from "react";
import CreateMarket from "./createMarket";
import CreateCarad from "./createCarad";

function Index() {
  return (
    <>
      <CreateMarket />
      <CreateCarad />
    </>
  );
}

export default Index;
