import React from "react";
import Line from "../../assets/Image/UnderLine-01.svg";
import dapp from "../../assets/Icon/Dapp.png";
import defi from "../../assets/Icon/defi.png";
import game from "../../assets/Icon/web3Game.png";
import multichain from "../../assets/Icon/multiChain.png";
import nativePayment from "../../assets/Icon/nativePayment.png";
import identification from "../../assets/Icon/SSL.png";
function ServiceNdDevelopment() {
  const data = [
    {
      pic: dapp,
      title: "DApp's Development",
      detail:
        "We are proficient in a variety of programming languages including Solidity and Rust, allowing us to create the ideal decentralised software for your web3 project.",
    },
    {
      pic: defi,
      title: "DeFi Development",
      detail:
        "Our web3 developers can create a feature-rich deFi platform for you whether it is a deFi crowdfunding platform or a deFi yield farming platform.",
    },
    {
      pic: game,
      title: "Web3 Game Production",
      detail:
        "With the help of our specialists, you may create the Web3 game of your dreams. Explore the world of web3 games driven by AR, VR, Unity, and 3D technologies for an enhanced user experience.",
    },
    {
      pic: multichain,
      title: "Multi-Chain Web3 Solutions",
      detail:
        "Using our carefully constructed Web3 multi-chain solutions. You may benefit from the security and efficiency of major blockchains like Ethereum and Binance.",
    },
    {
      pic: nativePayment,
      title: "Native Payment Methods",
      detail:
        "Access decentralised apps with our proprietary digital wallets. Which safely store native token's and effortlessly allow transactions.",
    },

    {
      pic: identification,
      title: "SSI Identification",
      detail:
        "Our systems are intended to offer users self-sovereignty identification and control over their personal data on the internet.",
    },
  ];
  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center align-items-center">
        <div
          className="col-lg-8 col-md-12 col-sm-12 mt-5"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1 className="Text-heading text-center">
            Web3 Solutions Hub Services
          </h1>
          <div className="text-center">
            <img src={Line} className="img-fluid" width={"40%"} />
          </div>
          <p className="text-detail  text-center mt-3">
            Virtual tech web3 development services span various verticals,
            encompassing every industry and business facet. Our professionals
            ensure that every service you select for your company is executed on
            time and without mistake.
          </p>
        </div>
        <div className="row justify-content-around align-items-center">
          {data.map((data, index) => {
            return (
              <>
                <div
                  key={index}
                  className="col-lg-3 col-md-5 col-sm-12 mt-3 mb-3 border-gradient"
                  style={{ height: "260px" }}
                  data-aos="zoom-in-up"
                  data-aos-duration="1000"
                >
                  <div className="  m-3">
                    <div className="mt-3 mb-3">
                      <div className="text-center">

                      <img
                        src={data.pic}
                        className="img-fluid"
                        width={"70px"}
                        alt=""
                        />
                        </div>
                      <h3 className="text-title ">{data.title}</h3>
                      <p className="text-detail  mt-3">{data.detail}</p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ServiceNdDevelopment;
