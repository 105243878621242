import React, {useEffect} from "react";
import Chatbot from "../../component/chatbot/index";

function ChatBot() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Chatbot />
    </>
  );
}

export default ChatBot;
