import React from "react";
const data = [
  {
    title: "Smart Contract",
    detail:
      "Facilitation Our skilled Metaverse developers   will implement smart contract solutions in your project to facilitate safe and transparent transactions.",
  },
  {
    title: "Wallet Integrations",
    detail:
      "Being the best metaverse development company  . We integrate crypto wallets into your platform to ease transactions.",
  },
  {
    title: "Decentralized Network",
    detail:
      "As a company dedicated to the development of the Metaverse  . We enable decentralized data transmission to create real-time connections.",
  },
  {
    title: "Interoperability",
    detail:
      "Our top Metaverse developers provide cost-effective interoperability standards to support your projects such as movies and 3D design.",
  },
  {
    title: "Wallet Integrations",
    detail:
      "Being the best metaverse development company  . we integrate crypto wallets into your platform to ease transactions.",
  },
  {
    title: "Maintenance",
    detail:
      "Our splendid Metaverse developers   ensure that your network stays on track by providing services for high usability and upgrade.",
  },
  // {
  //   title: "New advertisement models",
  //   detail:
  //     "We ensure the building of your brand in the market through our advanced marketing strategies for a better brand connection and user experience.",
  // },
];

function ExpertDeveloper() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-8 col-md-12 col-sm-12">
          <h1
            className="Text-heading text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Expert Metaverse Developers Project Assistance
          </h1>
          <p
            className="text-detail text-center mt-3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Virtual tech has been acing the metaverse game for the last 2+ years,
            with more than 500+ projects launched successfully. We can support
            your Metaverse project with all our might.
          </p>
        </div>

        <div className="row justify-content-around align-items-center">
          {data.map((data) => {
            return (
              <>
                <div
                  className="col-lg-3 col-md-5 col-sm-12 border-gradient mt-5"
                  style={{ height: "200px" }}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="m-3">
                    <h2 className="text-title">{data.title}</h2>
                    <p className="text-detail mt-3 ">{data.detail}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ExpertDeveloper;
