import React from "react";
import Models from "../models/model";
import { useState } from "react";

function Collaborate() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="collaborate-bg">
      <div className="container">
        <div className="row justify-content-center align-items-center mt-5 mb-5">
          <div className="col-lg-7 col-md-12 col-sm-12 mt-5 mb-5">
            <h1
              className="text-center Text-heading"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Custom Software Services Assessment
            </h1>
            <p
              className=" text-detail text-center mt-3"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              The leading software development company Virtual Tech provides
              specialised services customised to client's business needs such
              as API integration, bespoke software development, corporate
              software development and team augmentation. Customizations and
              full-cycle development are just two of the ways we help businesses
              stay ahead of the competition.
            </p>
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <button
                className="btn btn-experts p-3"
                onClick={() => setModalShow(true)}
              >
                Schedule A Call
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Collaborate;
