import React from "react";
import "./web3.css";
import Line from "../../assets/Image/UnderLine-01.svg";
import Models from "../models/model";
import { useState } from "react";

function Allindustries() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="industries-bg">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div
            className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h1 className="Text-heading text-center">
             Web3 Industry Solutions
            </h1>
            <div className="text-center">
              <img src={Line} className="img-fluid" alt="" width={"40%"} />
            </div>
            <p className="text-detail text-center mt-2">
              Looking for a reliable way to enter the Web3 world? Join hands
              with Virtual Tech and find your Web3 solution today.
            </p>
            <div className="text-center mt-3 mb-3">
              <button className="btn btn-experts p-3"  onClick={() => setModalShow(true)}>
                Schedule a consultation now
                
              </button>

              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Allindustries;
