import React from "react";
import Api from "../../assets/metaverse/metaverse1-02.svg";
import Integration from "../../assets/metaverse/metaverse2-01.svg";
import Nft from "../../assets/metaverse/metaverse3-01.svg";
import Dapp from "../../assets/metaverse/metaverse4-01-01.svg";
import Avatar from "../../assets/metaverse/metaverse5-01.svg";
import CryptoWallet from "../../assets/metaverse/cryptoWallet-01.svg";

const data = [
  {
    icon: Api,
    title: "API Integration",
    detail:
      "Our Metaverse developers  will integrate APIs into your Metaverse platform to help in sharing data and interacting better with several ecosystems.",
  },
  {
    icon: Integration,
    title: "Data Integration",
    detail:
      "Our Metaverse developers will help in data integration to incorporate different data from several environments and ecosystems existing in the virtual world.",
  },
  {
    icon: Nft,
    title: "NFT Marketplace Integration",
    detail:
      "Sell, purchase and trade NFT's through NFT Marketplace Integration. Virtual Tech can support NFT Marketplaces across, Ethereum, Polygon, Solana and many more.",
  },
  {
    icon: Dapp,
    title: "DApp's Integration",
    detail:
      "Leverage peer-to-peer services to secure user data on your Metaverse platform. Dapp will offer more privacy and transparency to your data as well as remove third-party alterations.",
  },

  {
    icon: Avatar,
    title: "Avatar Creation",
    detail:
      "Our seasoned Metaverse developers  can create a customized avatar of your users which will help them to interact well and build a social network in the Metaverse.",
  },
  {
    icon: CryptoWallet,
    title: "Crypto Wallets",
    detail:
      "Being the leading Metaverse development company. We will help you leverage the benefits of a crypto wallet integration to buy and sell assets with cryptocurrency.",
  },
];

function GameChanging() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5 mb-5">
        <div className="col-lg-10 col-md-12 col-sm-12">
          <h1
            className="Text-heading text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Virtual Revolution: Top-Tier Metaverse Developers
          </h1>
          <p
            className="text-detail text-center mt-3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            With an experience of 2+ years in Metaverse R&D. Virtual Tech ensures
            the embedment of all the essentially unique features in your
            Metaverse platform.
          </p>
        </div>
        <div className="row justify-content-between mobile-responsive align-items-center">
          {data.map((data) => {
            return (
              <>
                <div
                  className="col-lg-3 col-md-5 col-sm-12 border-gradient mt-4"
                  data-aos="flip-left"
                  data-aos-duration="1000"
                >
                  <div className="m-3">
                    <div className="text-center mb-3">
                      <img
                        src={data.icon}
                        className="img-fluid"
                        alt=""
                        width={"120px"}
                      />
                    </div>
                    <h3 className="text-title">{data.title}</h3>
                    <p className=" text-detail mt-3">{data.detail}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default GameChanging;
