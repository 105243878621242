import React from "react";
import PictureOne from "../../assets/Image/business.jpg";
import PictureTwo from "../../assets/Image/app2.jpg";
import PictureThree from "../../assets/Image/app3.jpg";
import PictureFour from "../../assets/Image/app4.jpg";
import PictureFive from "../../assets/Image/app5.jpg";
import PictureSix from "../../assets/Image/app6.jpg";

const data = [
  {
    image: PictureOne,
    title: "Analysis of Business",
    description:
      "We focus on knowing your requirements as the finest mobile app development company   before beginning the app development process.",
  },
  {
    image: PictureTwo,
    title: "Interface Design",
    description:
      "Our mobile app developers are experienced UI/UX designers who will guarantee that your app interface is built with the correct stack and is user-friendly.",
  },
  {
    image: PictureThree,
    title: "Backend support for Android and iOS",
    description:
      "Our mobile app development services are entirely focused on the requirements for improved transition loading speed and animation.",
  },
  {
    image: PictureFour,
    title: "Front-end Support",
    description:
      "Our  mobile app developers are fluent in front-end languages such as HTML, JavaScript and CSS. Our engineers are also proficient in ReactJS, AngularJS and jQuery.",
  },
  {
    image: PictureFive,
    title: "Testing and quality assurance",
    description:
      "Our mobile app development services will guarantee that the product is thoroughly tested and bug-free before its final release ensuring that it is market-leading.",
  },
  {
    image: PictureSix,
    title: "Launch and Support",
    description:
      "We guarantee total help from our professionals until the launch of your product. We also provide post-launch assistance through our maintenance facilities to ensure product quality.",
  },
];
function FullTime() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-10 col-md-12 col-sm-12 mt-5 mb-5">
          <h1 className="text-center Text-heading" data-aos="fade-up">
            Complete Mobile Solutions
          </h1>
          <p className="text-center text-detail" data-aos="fade-up">
            Use the finest Android and iOS app development to build a
            market-leading solution with increased engagement user happiness
            scalability and retention.
          </p>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row  justify-content-between mobile-responsive">
            {data.map((data, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="col-lg-4 col-md-12 border-card mt-4"
                    style={{ width: "30%" }}
                    data-aos={
                      index == 0 || 3
                        ? "flip-left"
                        : index == 1 || 4
                        ? "flip-down"
                        : "flip-right"
                    }
                    data-aos-delay="400"
                  >
                    <div className="text-center mt-3">
                      <img src={data.image} className="img-fluid" alt="" />
                    </div>

                    <div className="text-title text-capitalize mt-3">
                      <strong>{data.title}</strong>
                    </div>
                    <p className="text-detail  mt-3">{data.description}</p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default FullTime;
