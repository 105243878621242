import React from "react";
import "./web3.css";
import Line from "../../assets/Image/UnderLine-01.svg";
import thirdparty from "../../assets/Icon/no-thirdParty.png";
import transparency from "../../assets/Icon/Untitled-1.png";
import Accountability from "../../assets/Icon/echancedAccountability.png";
// import securtiy from "../../assets/Icon/"
import costumen from "../../assets/Icon/customerRelation.png";
import supplyChain from "../../assets/Icon/Supply--chain.png";
const data = [
  {
    Icon: thirdparty,
    title: "Lack of Third-Party Services",
    detail:
      "Cut down costs by eliminating reliance on third-party services. Trust us to handle all your needs comprehensively.",
  },
  {
    Icon: transparency,
    title: "Completely transparent",
    detail:
      "Every piece of information on your site will be kept in an immutable ledger with the help of blockchain integration, providing great security.",
  },
  {
    Icon: Accountability,
    title: "Extra Accountability",
    detail:
      "Easy traceability enhances accountability and transparency, ensuring a secure platform.",
  },
  {
    Icon: transparency,
    title: "Added Security",
    detail:
      "Layers of protection on a web3 platform make it impossible for hackers to change or remove any sensitive data.",
  },
  {
    Icon: costumen,
    title: "Stronger customer relationships",
    detail:
      "User-centric Web 3 systems give users ownership of their data. Additionally, the enhances customer relationships and trust.",
  },
  {
    Icon: supplyChain,
    title: "Supply Chain Management",
    detail:
      "By keeping an eye on every step of the procedure and rapidly spotting any weakness Web3 aids in supply chain management.",
  },
];
function Web3Development() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5 mb-5">
          <h1
            className="Text-heading text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Cutting-Edge Web3 Advantages
          </h1>
          <div
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={Line} className="img-fluid" alt="" width={"50%"} />
          </div>
          <p
            className=" text-detail  text-center mt-3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Having proficiently catered to over 250+ global clients with our
            high-quality services. We've earned the prestige of being the
            top-tier web3 development providers. Our steadfast dedication to
            consistency and a team that fosters innovation and excellence sets
            us apart in our domain.
          </p>
        </div>
        <div className="row  justify-content-around">
          {data.map((data, index) => {
            return (
              <>
                <div
                  className="col-lg-3 col-md-6 col-sm-12 border-gradient card-1 mt-3 mb-3"
                  key={index}
                  data-aos={
                    index == 0 || 3
                      ? "flip-left"
                      : index == 1
                      ? "fade-bottom"
                      : "fade-right"
                  }
                  data-aos-duration="1000"
                >
                  <div className="m-3">
                    <div className="text-center">
                      <img
                        src={data.Icon}
                        className="img-fluid"
                        width={"80px"}
                        alt=""
                      />
                    </div>
                    <h2 className="text-title mt-2 mb-2 text-left ">
                      <strong>{data.title}</strong>
                    </h2>
                    <p className="text-detail mt-3 mb-3">{data.detail}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Web3Development;
