import React from "react";
import chatbot1 from "../../assets/Image/chatbot2.jpg";
import chatbot2 from "../../assets/Image/chatbot3.jpg";

import chatbot3 from "../../assets/Image/chatbot4.jpg";

import chatbot4 from "../../assets/Image/chatbot5.jpg";

import chatbot5 from "../../assets/Image/chatbot6.jpg";

import chatbot6 from "../../assets/Image/chatbot7.jpg";

const data = [
  {
    image: chatbot1,
    title: "Manufacturing activities",
    describtion:
      "Automation is prevalent in the industrial industry. There is still room to streamline the front end. We can simply design AI chatbots that will improve client experiences with our chatbot development services  .",
  },
  {
    image: chatbot2,
    title: "Retail",
    describtion:
      "Retail chatbots created by premier chatbot development business will include live chat operators that will answer consumer inquiries in detail, give fast customer care and upsell items online.",
  },
  {
    image: chatbot3,
    title: "Healthcare Profession",
    describtion:
      "With our seamless chatbot creation services you can help patients and minimise operational concerns by being available 24 hours a day seven days a week. Patients may receive immediate healthcare assistance from your portal with our chatbot creation services.",
  },
  {
    image: chatbot4,
    title: "Pharmaceutical Industry",
    describtion:
      "Pharma chatbots like those in the healthcare business provide virtual help support that can answer any drug-related question directly to the consumer. AI-powered chatbot creation services offer interactive assistance to pharma customers.",
  },
  {
    image: chatbot5,
    title: "Industry of Education",
    describtion:
      "EdTech organisations and institutes will benefit from our chatbot creation services. These virtual assistants will automate and manage admissions processes. It will handle all of the tedious manual operations and assist with data management.",
  },
  {
    image: chatbot6,
    title: "Banking industry",
    describtion:
      "AI chatbot development may be utilised for a variety of banking activities such as data processing, balance information and process automation such as KYC. Use the best chatbot development services   to automate client operations.",
  },
];

function ChatbotDevelopment() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5 mb-5">
        <div className="col-lg-10 col-md-12 col-sm-12">
          <h1 className="Text-heading text-center" data-aos="fade-up">
            Industry-Wide Chatbot Services
          </h1>
          <p className="text-detail text-center mt-2" data-aos="fade-up">
            Traditional Methods of customer support consisted of emails, calls
            and social media. However they had a lot of complexities that
            affected business revenues. Present-day conversational chatbot
            development services can elevate industries and help them earn more,
            shorten sales cycles and cater to a global audience.
          </p>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
          <div className="row justify-content-center mobile-resposive">
            {data.map((data) => {
              return (
                <>
                  <div class="col-lg-4 col-md-12  col-sm-12 mt-4">
                    <div
                      class="card "
                      style={{ height: "100%", fontSize: "15px" }}
                      data-aos="fade-up"
                    >
                      <img
                        src={data.image}
                        class="card-img-top"
                        alt="..."
                        width={"80%"}
                      />
                      <div class="card-body ">
                        <h3 class="text-title  mt-3">{data.title}</h3>

                        <p class="card-text mt-3 ">{data.describtion}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatbotDevelopment;
