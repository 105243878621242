import React from "react";
// import ComingSoon from "../../assets/Coming Soon/comingsoon.png";
import Line from "../../assets/Image/UnderLine-01.svg";
import { useState } from "react";
import "./web3.css";
import Models from "../models/model";

function Web3() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="web3-bg">
      <div className="container">
        <div className="row justify-content-center top-margin">
          <div className="col-lg-8 col-sm-12 text-center mb-5">
            <h1
              className="Text-heading text-center mt-4"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
             Web3 Development Hub
            </h1>
            <div className="text-center mb-3">
              <img src={Line} className="img-fluid" alt="" width={"30%"} />
            </div>
            <p
              className="text-center text-detail mt-3"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Enter the new era of Web3 with a leading Web3 development company
              . Build an interactive and innovative Web3 platform that puts user
              experience first and is powered by breakthrough Augmented Real
              Virtual Reality, Blockchain and Artificial Intelligence
              technologies. Use our groundbreaking AI solutions to transform
              your business landscape.
            </p>
            <div className="mt-5 btn-responsive" data-aos="fade-up" data-aos-duration="1000">
              <button
                className="btn btn-experts p-3"
                onClick={() => setModalShow(true)}
              >
                Schedule a Consultation
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Web3;
