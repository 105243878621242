import React from "react";
import "./chatbot.css";
import chatbot from "../../assets/Image/Chatbot.png";
import Models from "../models/model";
import { useState } from "react";

function Chatbot() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="chatbot-bg">
      <div className="container">
      
        <div className="row top-margin">
          <div className="col-lg-6 col-md-12 col-sm-12 text-center mt-5 mb-5">
            <h1 className="Text-heading mt-5" data-aos="fade-up">
              Chatbot Development Company
            </h1>
            <p className="text-detail mt-3" data-aos="fade-up">
              Leverage the best AI-based chatbot development solutions in the
              Middle East and revolutionize your business with our exceptional
              services.
            </p>
            <div className="text-start mt-3 mb-5" data-aos="fade-up">
              <button
                className="btn btn-experts p-3"
                onClick={() => setModalShow(true)}
              >
                Build Your AI Chatbot
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 text-center mt-5 mb-5">
            <div className="tex-center">
              <img src={chatbot} className="img-fluid" alt="" width={"50%"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chatbot;
