import React from "react";
import ComingSoon from "../../assets/Coming Soon/comingsoon.png";
import "./iot.css";
import Models from "../models/model";
import { useState } from "react";

function Iot() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="iot-bg">
      <div className="container ">
        <div className="row top-margin">
          <div className="col-lg-6 col-md-12 col-sm-12 text-center mt-5 mb-5">
            <h1
              className="Text-heading"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              IoT App Leader
            </h1>
            <p
              className="text-detail"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Virtual Tech is a reliable IoT App Development Company that helps
              businesses understand the true potential of IoT with its expert
              IoT developers.
            </p>
            <div
              className="text-start mt-3"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <button
                className="btn btn-experts p-3"
                onClick={() => setModalShow(true)}
              >
                Start Your Journey Now
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Iot;
