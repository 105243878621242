import React from "react";
import Iot from "../../assets/Image/iot8.jpg";
import Models from "../models/model";
import { useState } from "react";

function Superior() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5 mb-5">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div>
            <img src={Iot} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <h1
            className="Text-heading"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            IoT Consulting Pioneer Beyond Numbers
          </h1>
          <p
            className="text-detail mt-5"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Virtual Tech a vanguard IoT services with a robust team of over 250
            developers assures secure coding and agile implementation for your
            IoT applications.
          </p>
          <ul
            className="text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <li>
              Leverage consulting services beyond a wide array of technical.
            </li>
            <li>
              capabilities Solve all business problems with the potential of
              IoT.
            </li>
          </ul>
          <div className="mt-3" data-aos="fade-up" data-aos-duration="1000">
            <button
              className="btn btn-experts p-3"
              onClick={() => setModalShow(true)}
            >
              Contact Us Now
            </button>
            <Models show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Superior;
