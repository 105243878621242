import React from "react";
import iot1 from "../../assets/Icon/iot-01.svg";
import iot2 from "../../assets/Icon/iot2-01.svg";
import iot3 from "../../assets/Icon/iot3-01.svg";
import iot4 from "../../assets/Icon/iot4-01.svg";
// import iot5 from "../../assets/Icon/iot5-01.svg";
import iot6 from "../../assets/Icon/iot6-01.svg";
import iot7 from "../../assets/Icon/iot7-01.svg";
import iot8 from "../../assets/Icon/iot8-01.svg";
import ImageEight from "../../assets/create market/cloud.png";

const data = [
  {
    image: iot1,
    title: "Sensing",
    description:
      "Sensor installation can detect changes in the environment as well as report the status; without sensors, there will be no effective IoT environment.",
  },
  {
    image: iot2,
    title: "Device Virtualization",
    description:
      "Being the best IoT App development company  , we standardize the integration of devices in the network by using device virtualization.",
  },
  {
    image: iot3,
    title: "High-speed Messaging",
    description:
      "This feature by our efficient IoT developers  will help in enabling reliable, bi-directional communication between the cloud and devices.",
  },
  {
    image: iot4,
    title: "Stream Processing ",
    description:
      "Choose the top artificial intelligence development firm, Virtual Tech, to assist your clients in identifying their files and extracting data to reduce data processing time.",
  },
  {
    image: iot4,
    title: "Task Automation",
    description:
      "Our esteemed IoT developers   will develop your IoT platform, which will automate most of your manual tasks while being time and cost-efficient.",
  },
  {
    image: iot6,
    title: "Data Encryption",
    description:
      "Data is encrypted and then protected while in transit by TLS. Additionally, the passwords are hashed using several encryption algorithms for max security.",
  },
  {
    image: iot7,
    title: "Notifications And Alarms ",
    description:
      "Use our top-notch IoT development services   for automatizing alerts and sending notifications through multiple services available on Cloud Code.",
  },
  {
    image: ImageEight,
    title: "IoT Integrations",
    description:
      "As the most trusted IoT development company  , we design simple APIs to create powerful integrations that promise excellent results.",
  },
  {
    image: iot8,
    title: "Voice And Speech Recognizations",
    description:
      "Leverage the power of AI to unleash voice and speech recognition with our high-end IoT developers.",
  },
];

function UniqueFeature() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-12 col-md-12 col-sm-12 mt-5 mb-5">
          <h1
            className="Text-heading text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Distinctive Edge: Premier IoT Developers 
          </h1>
          <p
            className="text-detail text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Virtual Tech strives to be better than the rest and we achieve this
            by building solutions that feature functionalities for every
            requirement.
          </p>
        </div>
        <div className="row d-flex justify-content-between mobile-responsive ">
          {data.map((data) => {
            return (
              <>
                <div
                  className="col-lg-3 col-md-5 col-sm-12 border-gradient border-gradient-purple mt-3 mb-3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="row justify-content-center ">
                    <div className="col-md-10 mt-3 mb-3">
                      {/* <div className="text-center">
                        <img
                          src={data.image}
                          className="img-fluid"
                          width={"120px"}
                          alt=""
                        />
                      </div> */}
                      <div className="mt-3">
                        <h6 className=" text-title mb-3">
                          <strong>{data.title}</strong>
                        </h6>
                        <p className="mt-2 text-description">
                          {data.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default UniqueFeature;
