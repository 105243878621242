import React from "react";
import Line from "../../assets/Image/UnderLine-01.svg";
import GrootPic from "../../assets/Image/groot.jpg";
import GrootPic2 from "../../assets/Image/groot2.jpg";

const data = [
  {
    title: "Our Purpose",
    detail:
      "At Virtual Tech, we are dedicated to pushing the boundaries of innovation and staying ahead of the competition. With unwavering passion, we transform our clients' business ideas into exceptional software solutions. As a value-driven organisation with a strong commitment to ethics, we strive to cultivate enduring client relationships built on trust in the virtual tech landscape.",
    by: "TEAM OF VIRTUAL TECH",
    image: GrootPic,
  },
];
const dataTwo = [
  {
    title: "Our Values",
    detail:
      "With a decade of pioneering virtual tech solutions, virtual tech has unleashed the true potential of technology on the world. Guided by a commitment to excellence, we uphold a high professional standard and maintain an independent perspective in the virtual tech landscape. Leveraging our influential global network, we deliver the finest services to our esteemed clients. Virtual Tech fosters an inclusive workspace where our employees mentor each other, collectively pursuing a holistic impact in the virtual tech industry. As the CEO of Virtual Tech, I am proud to lead our team towards continued innovation and success.  ",
    by: "TEAM OF VIRTUAL TECH",
    image: GrootPic2,
  },
];
function OurFounders() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-7 col-md-12 mt-5">
          <div className="mt-3">
            <h1 className="Text-heading text-center" data-aos="fade-up">
              Our Founder Members Are Keeping Up With The Digital Progression
            </h1>
            <div className="text-center mt-2" data-aos="fade-up">
              <img src={Line} className="img-fluid" width={"50%"} alt="" />
            </div>
          </div>
        </div>
        <div className="row justify-content-between align-items-center mobile-responsive mt-5 mb-5" >
          {data.map((data) => {
            return (
              <>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 mt-3"
                  data-aos="fade-up"
                >
                  <div>
                    <img
                      src={data.image}
                      className="img-fluid"
                      alt=""
                      width={"100%"}
                    />
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 mt-3"
                  data-aos="fade-up"
                >
                  <div>
                    <h3 className="Text-heading">{data.title}</h3>
                    <p className="text-detail text-justify">{data.detail}</p>
                    <h6 className="text-white">{data.by}</h6>
                  </div>
                </div>
              </>
            );
          })}
          {dataTwo.map((data) => {
            return (
              <>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 mt-3"
                  data-aos="fade-up"
                >
                  <div>
                    <h3 className="Text-heading">{data.title}</h3>
                    <p className="text-detail text-justify">{data.detail}</p>
                    <h6 className="text-white">{data.by}</h6>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-12 col-sm-12 mt-3"
                  data-aos="fade-up"
                >
                  <div>
                    <img
                      src={data.image}
                      className="img-fluid"
                      alt=""
                      width={"80%"}
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OurFounders;
