import React from "react";
import Models from "../models/model";
import { useState } from "react";

function Productive() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="leverage-bg mt-5 mb-5">
      <div className="container">
        <div className="row justify-content-center align-items-center ">
          <div className="col-lg-10 col-md-12 col-sm-12 mt-5 mb-5 ">
            <h1
              className="Text-heading text-center "
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              AI Powerhouse Highly Productive Company
            </h1>
            <p
              className="text-center text-detail"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Virual Tech has highly experienced AI engineers that have
              previously built an array of innovative AI products and it time
              for yours to be the next.
            </p>
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <button
                className="btn btn-experts p-3"
                onClick={() => setModalShow(true)}
              >
                Contact Us
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Productive;
