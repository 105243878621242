import React from "react";
import Industrial from "../../assets/Image/iot2.jpg";
import Digital from "../../assets/Image/iot3.jpg";
import Healthcare from "../../assets/Image/iot5.jpg";
import Building from "../../assets/Image/iot4.jpg";
import FoodTech from "../../assets/Image/iot6.jpg";
import Fitness from "../../assets/Image/iot7.jpg";
// import Gaming from "../../assets/Image/ecommere-06.jpg";
const data = [
  {
    image: Industrial,
    title: "Industrial IoT Solution",
    describtion:
      "Our valuable IoT developers in   realize that IoT is still in its R&D phase and can bring accuracy to businesses at a considerable rate.",
  },
  {
    image: Digital,
    title: "Digital Asset Tracking",
    describtion:
      "Redesign IoT strategies with the best IoT app development services   to reconfigure a workforce and support with quality services.",
  },
  {
    image: Building,
    title: "Building Automation",
    describtion:
      "Unlock the potential of the Internet of Things and track digital assets with ethical principles of human and machine collaboration.",
  },
  {
    image: Healthcare,
    title: "Healthcare IoT Solutions",
    describtion:
      "Utilize the brilliance of the internet of things to create intelligent machines for glucose monitoring, heart rate monitoring and much more.",
  },
  {
    image: FoodTech,
    title: "FoodTech IoT Solutions",
    describtion:
      "Leverage IoT to maintain the highest safety standards in your food business and track, and monitor the quality of foodstuffs.",
  },
  {
    image: Fitness,
    title: "Fitness IoT Solutions",
    describtion:
      "Utilize the power of IoT from our IoT app developers in the   to leverage activity trackers for cancer treatments and specialized heart monitors.",
  },
];

function IotApplication() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-10 col-md-12 col-sm-12 mt-5 mb-5">
          <h1 className="Text-heading text-center" data-aos="fade-up">
            IoT Development Industry Revolution Advanced Applications
          </h1>
          <p className="text-detail text-center mt-3" data-aos="fade-up">
            Elevate your business performance and ROI with Virtual Tech
            leading-edge IoT application development services. Discover the
            limitless potential of IoT in transforming diverse industry sectors.
          </p>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
          <div className="row justify-content-center mobile-resposive">
            {data.map((data, index) => {
              return (
                <>
                  <div class="col-lg-4 col-md-12 col-sm-10 mt-4">
                    <div
                      class="card "
                      style={{ height: "100%", fontSize: "15px" }}
                      key={index}
                    >
                      <img src={data.image} class="card-img-top" alt="..." />
                      <div class="card-body ">
                        <h3 class="card-title  mt-3">{data.title}</h3>

                        <p class="card-text  mt-3 ">{data.describtion}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IotApplication;
