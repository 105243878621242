import React from "react";
const data = [
  {
    no: "01",
    title: "Define",
    description:
      "Our experienced chatbot developers will collaborate with you to thoroughly investigate the industry and understand your company requirements.",
  },
  {
    no: "02",
    title: "Design ",
    description:
      "Our A-level Chatbot development services are backed by highly skilled UI/UX designers that strive to produce an app interface that is easy to use.",
  },
  {
    no: "03",
    title: "Deploy",
    description:
      " Launch your bot into the market and carve out your own niche for discovering and satisfying your business needs.",
  },
  {
    no: "04",
    title: "Develop",
    description:
      "As a top chatbot creation firm we create bots that use NLP and AI to learn intentions, contexts and plenty more.",
  },
  {
    no: "05",
    title: "Deliver",
    description:
      " Our chatbot creation services accompany you from the beginning to the conclude of your business journey including post-launch assistance.",
  },
];

function Concentrate() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5 mb-5">
        <div className="col-lg-10 col-md-12 col-sm-12">
          <h1 className="Text-heading text-center" data-aos="fade-up">
            SEO-Optimized Chatbots
          </h1>
          <p className="text-detail text-center mt-3" data-aos="fade-up">
            At Virtual Tech, we use a simplified method to provide outcomes on
            time. Explore our chatbot development process to learn how it comes
            to life.
          </p>
        </div>
        <div className="col-lg-12 col-md-12 mt-3">
          <div className="row justify-content-around">
            {data.map((data) => {
              return (
                <>
                  <div
                    className="col-lg-5 col-md-10 col-sm-11 mt-3 mb-3"
                    data-aos="flip-right"
                  >
                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-3 ">
                        <h1 className="text-center text-number">{data.no}</h1>
                      </div>
                      <div className="col-md-9">
                        <h1 className="top-heading">{data.title}</h1>
                        <p className="text-detail">{data.description}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Concentrate;
