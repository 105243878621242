import React from "react";
// import "./frequenltyAccordion.css";
import Line from "../../assets/Image/UnderLine-01.svg";
// import Line from "../../assets/Image/UnderLine-01.svg";

import Accordion from "react-bootstrap/Accordion";

const accordionData = [
  {
    title: "What opportunities does Metaverse offer for businesses  ?",
    key: 1,
    detail:
      "With unlimited possibilities in the Metaverse, Metaverse development companies   can create customizable avatars attend entertainment events such as concerts and games, buy NFTs and other digital assets, own virtual land, socialize and network and create their own VR experiences.",
  },
  {
    key: 2,
    title: "How can businesses  profit from the metaverse? ",
    detail:
      "Users may earn money in a variety of methods, including NFTs, crypto mining, streaming, the P2E gaming model and many others.",
  },
  {
    key: 3,
    title:
      "Name some major investors and developers who construct virtual-world platforms?",
    detail:
      "Some of them include Microsoft, NVIDIA, Epic Games, Decentraland, Roblox, The Sandbox and Cryptovoxels.",
  },
];
const accordionDataTwo = [
  {
    key: 4,
    title: "What exactly is the need for the metaverse  ?",
    detail:
      "The metaverse will provide users with real-time experiences and interactions through the use of digital avatars.",
  },
  {
    key: 5,
    title: "Why should  firms enter the metaverse?",
    detail:
      "Businesses can enter the virtual world to develop their own metaverse experience, cooperate with an existing metaverse platform, sell digital assets or copies of real-world objects, purchase virtual properties and organise virtual events.",
  },
];
function FrequentlyAsked() {
  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5" data-aos="fade-up">
          <h1 className="Text-heading text-center">
            Frequently Asked Questions
          </h1>
        </div>
        <div className="col-lg-5 col-md-12 mt-3">
          <div className="text-center">
            <img src={Line} className="img-fluid" alt="" width={"70%"} />
          </div>
        </div>
        <div className="row justify-content-between mt-5 mb-5">
          <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
            {accordionData.map((data) => {
              return (
                <>
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 mt-3"
                    data-aos="fade-up"
                  >
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body>{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
            {accordionDataTwo.map((data) => {
              return (
                <>
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 mt-3"
                    data-aos="fade-up"
                  >
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body>{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default FrequentlyAsked;
