import React from "react";
const data = [
  {
    no: "01",
    title: "IoT Privacy",
    description:
      "Virtual Tech IoT development services ensure that all privacy programs are in place. We apply appropriate logical, technical, and administrative measures to allow only authorized data access.",
  },
  {
    no: "02",
    title: "Scalable App Architecture",
    description:
      "Virtual Tech utilizes a microservice architecture for developing maintainable and independently scalable applications. We offer smooth performance and a fast data retrieval process with our services.",
  },
  {
    no: "03",
    title: "DevOps",
    description:
      "Being a leading IoT app development company in  , Virtual Tech follows a strict process in app development with continuous integration and monitoring procedures through the latest tech stack and tools.",
  },
  {
    no: "04",
    title: "Automation Testing",
    description:
      "Virtual Tech has seasoned testing experts who provide quality and scale businesses at high rates. We provide security testing, API testing, Integration testing, and much more.",
  },
];

function Transforming() {
  return (
    <div className="development-bg">
      <div className="container">
        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-lg-8 col-md-12 col-sm-12 mt-5">
            <h1
              className="Text-heading text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Dynamic IoT Strategies Transforming Digital Ecosystems Unveiled
            </h1>
            <p
              className="mt-2 text-detail text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              To create innumerable possibilities in the field of IoT, we carve
              out the best possibilities for various enterprises
              with our approaches.
            </p>
          </div>
          <div className="col-lg-12 col-md-12 mt-3">
            <div className="row justify-content-around">
              {data.map((data) => {
                return (
                  <>
                    <div
                      className="col-lg-5 col-md-10 col-sm-11 mt-3 mb-3"
                      data-aos="flip-left"
                      data-aos-duration="1000"
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-3 ">
                          <h1 className="text-center text-number">{data.no}</h1>
                        </div>
                        <div className="col-md-9">
                          <h1 className="top-heading">{data.title}</h1>
                          <p className="text-detail">{data.description}</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transforming;
