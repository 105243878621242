import React from "react";
const data = [
  {
    title: "IoT App Development",
    point1:
      "Empower your business with Suffescom superior IoT development services  , crafting IoT apps for an unmatched user experience.",
    point2: "Build hybrid, web on the OS of your choice",
    point3:
      "Make IoT apps integrated with AI-ML to provide valuable project insights",
    point4: "Personalize user experience with customizable features",
    // point5: "High working standards",
  },
  {
    title: "IoT Testing and Maintenance",
    point1:
      "Our group guarantees ideal execution with IoT testing and upkeep administrations. Fabricate an impeccable item that will stand out.",
    point2: "Test the total IoT environment and combinations",
    point3:
      "  Get nonstop administrations by specialists through talk, email, and telephone",
    point4: "Influence our various item tests to get ideal execution",
  },
  {
    title: "IoT Wearables Network",
    point1:
      "Virtual Tech centres around making applications that interface with various gadgets through wires or a remote association.",
    point2: "Absorb mechanized gadgets and handle monstrous information flood",
    point3:
      " Construct adaptable engineering where the correspondence stack is nonconcurrent      ",
    point4:
      "Make programming projects available for updates and upkeep without changing equipment change",
    // point5: "Real-time update",
  },
  {
    title: "Savvy Home Arrangements With IoT Applications",
    point1:
      "Our IoT application engineers  help control house machines by using a framework approach known as IoT robotization.",
    point2:
      "Our group is capable of building savvy homes with IoT and numerous utility elements",
    point3:
      "Effectively control warming and cooling frameworks with a few IoT-empowered items and applications      ",
    point4: "Associate electrical machines with your cell phones",
    // point5: "High working standards",
  },
  {
    title: "IoT Apps In Connected Ecosystems",
    point1:
      " Being the main IoT application improvement organisation in  , we stretch out our administration to take care of IoT applications in associated environments.",
    point2:
      "Assemble your IoT application for associated environments and load them with cutting edge highlights",
    point3:
      " Accomplish your objective with an expansive organisation of associated and dependent innovations",
    point4:
      "Get arrangements that match your very good quality business necessities and convey results",
  },
  {
    title: "Information Investigation and Representation",
    point1:
      "Leverage our skilled IoT designers in the  , expertly crafting data into rich insights from diverse streams for transformative business intelligence.",
    point2:
      "Analyse visual data from smart devices as well as store vast amounts of data on multiple devices",
    point3:
      "Build a physical and virtual infrastructure for handling data from a multitude of devices",
    point4:
      "Gain valuable business insights from data with intuitive and stunning dashboards",
    // point5: "Real-time update",
  },
  {
    title: "IoT Door Advancement",
    point1:
      "IoT Door is an integrated centre point that interfaces IoT gadgets with cloud-based registration. Limit free time and work on the functional congruity of frameworks.      ",
    point2: "Use IoT door Advancement for altered identification ",
    point3:
      "Influence encryption equipment and easy-to-understand installment entryways",
    point4: "Use the force of crypto-motors for a decentralised encounter",
    // point5: "Real-time update",
  },
  {
    title: "IoT Application Arrangements",
    point1:
      "Influence the immaculate administrations of   best IoT application improvement organization to associate gadgets, oversee undertakings and safely move data.",
    point2:
      "Get the best IoT applications that assist with taking care of certifiable issues",
    point3: " Get aptitude in SDKs, famous IoT systems, and dialects",
    point4:
      " Ingest, change, and dissect information in the IoT biological system",
    // point5: "Real-time update",
  },
  {
    title: "Voice Interchanges",
    point1:
      "Utilize our awesome IoT application engineers   to applicate voice control in your item for home security, family wellbeing checking, and significantly more.",
    point2: " Eliminate numerous boundaries to your business' prosperity",
    point3: " Work on the individual personal satisfaction of your clients",
    point4: " Impart to lay out a relationship with an innovative gadget",
    // point5: "Real-time update",
  },
];

function Unleashing() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-10 col-md-12 col-sm-12 mt-5 mb-5">
          <h1
            className="Text-heading text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Unleashing Potential with Virtual Tech Revolutionary IoT Development
            Services
          </h1>
          <p
            className="text-detail text-center mt-2"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            As the best IoT development company in  , we have worked with this
            tremendous technology for over a decade and delivered several
            market-leading products.
          </p>
        </div>
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-12 col-md-12 mb-5">
            <div className="row ">
              {data.map((data) => {
                return (
                  <>
                    <div
                      className="col-lg-4 col-md-12 col-sm-12 mt-4"
                      data-aos="flip-left"
                      data-aos-duration="1000"
                    >
                      <div
                        className="card card-1"
                        style={{ height: "100%icon", fontSize: "16px" }}
                      >
                        <div className="text-left m-4">
                          <h3 className="">{data.title}</h3>
                          <h6 className=" mt-4">{data.point1}</h6>
                          <ul className="mt-4">
                            <li>{data.point2}</li>
                            <hr />
                            <li>{data.point3}</li>
                            <hr />
                            <li className="">{data.point4}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unleashing;
