import React from "react";
import "./everybusiness.css";
// import Card from "react-bootstrap/Card";
import Line from "../../../assets/Image/UnderLine-01.svg";
import PictureOne from "../../../assets/EveryBusiness/Picture1.png";
import PictureTwo from "../../../assets/EveryBusiness/Picture2.png";
import PictureThree from "../../../assets/EveryBusiness/Picture3.png";
import PictureFour from "../../../assets/EveryBusiness/Picture4.png";

const data = [
  {
    image: PictureFour,
    title: "Introducing ZKEVM",
    description:
      "Unlocking Privacy and Scalability on Ethereum. Experience the power of zero-knowledge proofs in enhancing transaction security and efficiency. Embrace advanced privacy and improved performance in decentralized applications and smart contracts.",
  },
  {
    image: PictureThree,
    title: "Startups",
    description:
      "Embrace the Technological Revolution with Our Cutting-Edge Digital Solutions, as We Empower Startups to Thrive and Scale Their Business. As a Premier Blockchain Software Development Company  , We Enable Startups to Join the Vanguard of Innovation.",
  },
  {
    image: PictureTwo,
    title: "SMEs",
    description:
      "Unlock the Power of Blockchain Software Solutions  , Empowering SMEs to Seize Competitive Advantages. Experience Enhanced Customer Reach, Streamlined Revenue Generation, and Accelerated Growth with Our Tailor-Made Solutions.",
  },
  {
    image: PictureOne,
    title: "NGO & Govt. Bodies",
    description:
      "Empowering Government Bodies and NGOs with Custom Blockchain Solutions, Our Team Drives Transparency, Trust, and Enhanced Functionality. Elevate Platform Security and Performance with our Innovative Solutions.",
  },
];
function EveryBusiness() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5 mb-5">
        <div className="col-lg-10 col-md-12 col-sm-12">
          <div className="text-center">
            <h1 className="Text-heading text-center">
              Unlocking Blockchain Business Potential
            </h1>
            <div className="" data-aos="fade-up">
              <img src={Line} className="img-fluid" alt="" width={"50%"} />
              <p className="text-detail text-center mt-3">
                Unleash the potential of Virtual Tech, the top blockchain
                software development company. With 5 years of experience, we
                shape technologies into efficient solutions for organizations of
                all sizes. Experience the power of blockchain and drive your
                business to new heights with us.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row  justify-content-between mobile-responsive">
            {data.map((data) => {
              return (
                <>
                  <div
                    className="col-lg-3 col-md-12 col-sm-12 border-card mt-4"
                    // data-aos="flip-up"
                  >
                    <div className="text-center mt-3">
                      <img src={data.image} className="img-fluid" alt="" />
                    </div>

                    <div className="text-title  text-capitalize mt-3">
                      <strong>{data.title}</strong>
                    </div>
                    <p className="text-detail mt-3">{data.description}</p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EveryBusiness;
