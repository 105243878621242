import React from "react";
import mobile from "../../assets/Image/mobile.jpg";
import Website from "../../assets/Image/website.jpg";
import Desktop from "../../assets/Image/desktop.jpg";
import Database from "../../assets/Image/database.jpg";

const data = [
  {
    image: mobile,
    title: "Mobile application development",
    describtion:
      "We develop iOS, Android, and cross-platform apps while assisting you through each stage of the process from designing an app to building it with all its features and functions.",
  },
  {
    image: Website,
    title: "Website Development",
    describtion:
      "The best software development firms are creating strong web apps by combining high-quality architecture, coding, design and testing.",
  },
  {
    image: Desktop,
    title: "Desktop Application",
    describtion:
      "Select a trustworthy custom software development firm to transform your business idea into a desktop solution to meet your business needs.",
  },
  {
    image: Database,
    title: "Database Administration",
    describtion:
      "Our professional bespoke software development team has years of expertise in providing cutting-edge software solutions and new features to our clients. ",
  },
];
function TypeCustom() {
  return (
    <div className="collaborate-bg">
      <div className="container">
        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-lg-10 col-md-12 col-sm-12 mt-5">
            <h1
              className="text-center Text-heading"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Types of Custom Software We Offer
            </h1>
            <p
              className="text-center text-detail"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Virtual Tech offers a variety of bespoke software development
              services depending on your company's needs in order to
              streamline daily tasks and accelerate development. Before
              providing our competitively priced services we understand our
              clients company requirements and assess how our software solution
              helps their firm's growth.
            </p>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3 mb-5">
            <div className="row justify-content-between mobile-resposive">
              {data.map((data) => {
                return (
                  <>
                    <div
                      class="col-lg-3  col-md-12 mt-4"
                      data-aos="flip-left"
                      data-aos-duration="1000"
                    >
                      <div class="card " style={{ height: "100%" }}>
                        <img
                          src={data.image}
                          class="card-img-top"
                          alt="..."
                          width={"80%"}
                        />
                        <div class="card-body ">
                          <h3 class="card-title mt-3">{data.title}</h3>

                          <p class="card-text  mt-3 ">{data.describtion}</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TypeCustom;
