import React from "react";
import iot from "../../assets/Image/iot9.jpg";
import Models from "../models/model";
import { useState } from "react";

function BuildDevice() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5 mb-5">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <h1
            className="Text-heading"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Maximize Potential Embedded Software Expertise Unleashed
          </h1>
          <p
            className="text-detail mt-5"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Virtual Tech develops firmware as well as several embedded systems
            for integrating smart devices into IoT infrastructure.
          </p>
          <ul
            className="text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <li>AOSP development and support.</li>
            <li>DSP and microcontroller programming.</li>
            <li>Utilize CloudBridge development.</li>
          </ul>
          <div className="mt-3" data-aos="fade-up" data-aos-duration="1000">
            <button
              className="btn btn-experts p-3"
              onClick={() => setModalShow(true)}
            >
              Contact Us Now
            </button>
            <Models show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div data-aos="fade-up">
            <img src={iot} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuildDevice;
