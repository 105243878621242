import React, {useEffect} from "react";
import Ai from "../../component/ai/index";

function AI() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Ai />
    </>
  );
}
export default AI;
