import React from "react";
import "./software.css";
import software from "../../assets/Icon/software-01.svg";
import Models from "../models/model";
import { useState } from "react";

function Software() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="software-bg ">
      <div className="container top-margin">
        <div className="row  ">
          <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5">
            <h1
              className="Text-heading"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Custom Software Experts
            </h1>
            <p
              className="text-detail"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Make scalable future-proof solutions with our bespoke software
              development services. Increase throughput by utilising the latest
              advancements in software delivery by our agile cross-functional
              teams. We measure our performance based on your achievements.
            </p>
            <div data-aos="fade-up">
              <button
                className="btn btn-experts p-3"
                onClick={() => setModalShow(true)}
              >
                Talk To Our Experts
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5">
            <div className="text-center">
              <img src={software} alt="" className="img-fluid" width={"60%"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Software;
