import React from "react";
import Iot from "./iot";
import SuperCharge from "./superCharge";
import Unleashing from "./unleashing";
import IotApplication from "./iotApplication";
import Superior from "./superior";
import BuildDevice from "./buildDevice";
import IotChatbot from "./iotChatbot";
import Transforming from "./transforming";
import IotTechnology from "./iotTechnology";
import UniqueFeature from "./uniqueFeature";
import Ultilizes from "./ultilizes";
import FrequentlyAsked from "./frequentlyAsked";

function Index() {
  return (
    <>
      <Iot />
      <SuperCharge />
      <Unleashing />
      <IotApplication />
       <Superior />
      <BuildDevice />
      <IotChatbot />
      <Transforming />
      <IotTechnology />
      <UniqueFeature />
      <Ultilizes />
      <FrequentlyAsked />
    </>
  );
}

export default Index;
