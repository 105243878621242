import React from "react";
import Software from "./software";
import LeverageCustom from "./leverageCustom";
import Collaborate from "./collaborate";
import CustomSoftware from "./customSoftware";
import TypeCustom from "./typeCustom";
import Industries from "./industries";
import Follow from "./follow";
import CustomSoftwareDevelopment from "./customSoftwareDevelopment";
import VirtualSoftware from "./virtualSoftware";

function Index() {
  return (
    <>
      <Software />
      <LeverageCustom />
      <Collaborate />
      <CustomSoftware />
      <TypeCustom />
      <Industries />
      <Follow />
      <CustomSoftwareDevelopment />
      <VirtualSoftware />
    </>
  );
}

export default Index;
