import React from 'react'

function DubaiApp() {
  return (
    <>
    </>
    // <div>dubaiApp</div>
  )
}

export default DubaiApp