import React from "react";
import Line from "../../assets/Image/UnderLine-01.svg";
const data = [
  {
    no: "01",
    title: "Project Mapping",
    description:
      "The first stage is to sketch up the goals, characteristics and methods for achieving them. This procedure will be based on your Web3 consultation with our staff.",
  },
  {
    no: "02",
    title: "UI/UX Design",
    description:
      "The next stage is to create a unified front end framework for your web3 platform to guarantee that the user experience is excellent and everything is safe.",
  },
  {
    no: "03",
    title: "Development",
    description:
      "We select the optimal technology stack and construct the layers on your server side. All APIs, programming interfaces, back-end logic and so on are guaranteed to be error-free.",
  },
  {
    no: "04",
    title: "Testing and Deployment",
    description:
      "When the platform is complete, it goes through a rigorous process of sequential testing to eliminate any vulnerabilities or flaws before being deployed globally.",
  },
  {
    no: "05",
    title: "Maintenance and Support",
    description:
      "Every part of the development process is covered by our 360° solutions. We provide continuing maintenance and support once the platform is launched.",
  },
  {
    no: "06",
    title: "Documentation",
    description:
      "Embrace our virtual technology for efficient, streamlined project documentation, boosting team productivity. Through our collaborative approach, we provide tailored documentation tools that enhance understanding, communication and align your project goals effectively.",
  },
];

function DevelopmentWeb3() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div
          className="col-lg-8 col-md-12 col-sm-12 mt-5 mb-5"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1 className="Text-heading text-center mt-5">
            Business-Oriented Web3 Approach
          </h1>
          <div className="text-center">
            <img src={Line} className="img-fluid" alt="" width={"40%"} />
          </div>
          <p className="text-detail text-center mt-3">
            Our simplified methodology and carefully picked tech stack are
            responsible for our 1450+ delighted clients worldwide. Our Web3
            development process is comprehensive beginning with project
            conception and ending with support and maintenance. Let us take you
            through our steps one by one.
          </p>
        </div>
        <div className="col-lg-12 col-md-12 mt-3">
          <div className="row justify-content-around justify-content-between">
            {data.map((data) => {
              return (
                <>
                  <div
                    className="col-lg-5 col-md-10 col-sm-11 mt-3 mb-3"
                    data-aos="zoom-in-up"
                    data-aos-duration="1000"
                  >
                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-3 ">
                        <h1 className="text-center text-number">{data.no}</h1>
                      </div>
                      <div className="col-md-9">
                        <h1 className="top-heading">{data.title}</h1>
                        <p className="text-detail">{data.description}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevelopmentWeb3;
