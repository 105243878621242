import React, {useEffect} from "react";
import PortfolioComp from "../../component/portfolio/Portfolio";

function Portfolio() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <div>
      <PortfolioComp />
    </div>
  );
}

export default Portfolio;
