import React from "react";

const data = [
  {
    no: "01",
    title: "Create a community",
    description:
      "Community is an integral part of interacting with the metaverse through avatars. Our Metaverse developers   will help you create these valuable connections for future endeavours in your company.",
  },
  {
    no: "02",
    title: "Launch Events",
    description:
      " After forming your own community through avatar interactions, you may continue to organise events in the metaverse, such as business gatherings or weddings, with the help of our smooth metaverse developers  .",
  },
  {
    no: "03",
    title: "Product Promotion",
    description:
      "Through our Metaverse development services, you can simply market your items in the Metaverse by using adverts such as virtual billboards and banners. In the virtual world, you can also sponsor or host events.",
  },
  {
    no: "04",
    title: "Utilise Virtual Tours",
    description:
      "Virtual Tech Solution, an efficient Metaverse Development Company  , would facilitate virtual tours for users to promote meaningful and immersive interactions among groups.",
  },
];
function UseCase() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-10 col-md-12 col-sm-12">
          <h1
            className="text-center Text-heading"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Metaverse Development Use-Cases
          </h1>
          <p
            className="text-center text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Being a reliable metaverse development company . We ensure that your
            platform serves its cause and helps you chase your business goals
            with a constant revenue stream.
          </p>
        </div>
        <div className="col-lg-12 col-md-12 mt-3">
          <div className="row justify-content-around">
            {data.map((data) => {
              return (
                <>
                  <div
                    className="col-lg-5 col-md-10 col-sm-11 mt-3 mb-3"
                    data-aos="flip-left"
                    data-aos-duration="1000"
                  >
                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-3 ">
                        <h1 className="text-center text-number">{data.no}</h1>
                      </div>
                      <div className="col-md-9">
                        <h1 className="top-heading">{data.title}</h1>
                        <p className="text-detail">{data.description}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UseCase;
