import React from "react";
import ImageOne from "../../../assets/create market/enterprise.png";
import ImageTwo from "../../../assets/create market/marketplace.png";
import ImageThree from "../../../assets/create market/Metaverse.png";
import ImageFour from "../../../assets/create market/walletdevelopment.png";
import ImageFive from "../../../assets/create market/P2E.png";
import ImageSix from "../../../assets/create market/etherum.png";
import ImageSeven from "../../../assets/create market/smartContract.png";
import ImageEight from "../../../assets/create market/cloud.png";
import ImageNine from "../../../assets/create market/blockchain-security.png";

const data = [
  {
    image: ImageOne,
    title: "Enterprise Blockchain Consultant",
    description:
      "Our professional team of consultants provides outstanding guidance on techniques for optimising business operations. They begin by thoroughly evaluating the blockchain and carrying out the intended strategy.",
  },
  {
    image: ImageTwo,
    title: "NFT Marketplace Development",
    description:
      "Our skilled blockchain developers provide full-stack blockchain software development services. We have a dedicated team of NFT engineers creating scalable and secure decentralized solutions. Partner with us for innovative and secure blockchain development services.",
  },
  {
    image: ImageThree,
    title: "Metaverse Development",
    description:
      "Hire skilled developers for advanced cross-platform cryptocurrency wallet development. Our bespoke solutions prioritize security, smart contract performance, and transaction peace of mind, elevating your blockchain wallet experience.",
  },
  {
    image: ImageFour,
    title: "Blockchain Wallet Development",
    description:
      "We design secure, cutting-edge cryptocurrency wallets with cross-platform capabilities and exceptional smart contract performance. Enhance transaction security and gain peace of mind with our bespoke blockchain wallet development solutions.",
  },
  {
    image: ImageFive,
    title: "P2E Game Development",
    description:
      "We're web3 game development experts specializing in play-to-earn games that combine crypto and web3 technologies. Join us for immersive gaming experiences where players earn real value through in-game activities. Revolutionize gaming with blockchain and web3, monetize skills and assets.",
  },
  {
    image: ImageSix,
    title: "EVM Base Development",
    description:
      "We are an EVM-based development company specializing in smart contracts, dApps, and blockchain solutions. Partner with us to unlock the power of Ethereum and drive innovation in your projects.",
  },
  {
    image: ImageSeven,
    title: "Smart Contract Development",
    description:
      "Empower Your Business with Custom-Designed Smart Contracts Tailored to Your Project Requirements. Trust in Our Expertise to Deploy Smart Contracts with Unwavering Confidence, Transparency, Security, Speed, and Efficiency.",
  },
  {
    image: ImageEight,
    title: "Cloud Blockchain Development",
    description:
      "Leverage the Power of Leading Cloud Platforms like Microsoft Azure, IBM Blockchain Platform, Google Cloud Platform, and Amazon Managed Blockchain to Optimize Data Storage, Processing, and Management. Experience the Benefits of Secure and Efficient Remote Server Solutions.",
  },
  {
    image: ImageNine,
    title: "Blockchain Security",
    description:
      "Fortify Your Systems Against Cyber Threats with Our Comprehensive Blockchain Security Solution. Experience the Power of Robust Cybersecurity Frameworks, Advanced Risk Management Systems, Multiple Security Layers, High-Speed Data Transfer Processors, and Cost-Effective Compliance Measures.",
  },
];
function CreateCarad() {
  return (
    <div className="container">
      <div className="row d-flex justify-content-between mobile-responsive ">
        {data.map((data) => {
          return (
            <>
              <div
                className="col-lg-3 col-md-5 col-sm-12 border-gradient border-gradient-purple mt-3 mb-3"
                data-aos="flip-left"
                data-aos-duration="1000"
              >
                <div className="row justify-content-center ">
                  <div className="col-md-10 mt-3 mb-3">
                    <div className="text-center">
                      <img
                        src={data.image}
                        className="img-fluid"
                        width={"120px"}
                        alt=""
                      />
                    </div>
                    <div className="mt-3">
                      <h6 className="text-white text-title mb-3">
                        <strong>{data.title}</strong>
                      </h6>
                      <p className="mt-2 text-description text-left ">
                        {data.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default CreateCarad;
