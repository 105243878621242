import React from "react";
import "./designing.css";

function Designing() {
  return (
    <div className="design-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12 col-sm-12 design-BgPic">
            <div className="row justify-content-center m-5">
              <div
                className="col-lg-10 col-md-12 col-sm-12"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="text-center mt-4 mb-4">
                  <h2 className=" Text-heading text-center">
                    Revolutionizing Business with Blockchain
                  </h2>
                  <p className="text-center text-detail mt-4">
                    With our blockchain solutions you can meet the future of
                    business and technology. Discuss about your project.
                  </p>
                  <div className="btn-responsive">
                    <button className="btn btn-experts p-3 mt-3">
                      Talk to Experts
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Designing;
