import React, {useEffect} from "react";
import MetaverseComp from "../../component/metaverse/index";

function Metaverse() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <MetaverseComp />
    </>
  );
}

export default Metaverse;
