import React from "react";
import FixedBudget from "../../assets/Icon/teampriceidea-03.svg";
import WhiteLable from "../../assets/Icon/teampriceidea-01.svg";
import Renting from "../../assets/Icon/Renting-01.svg";
const data = [
  {
    icon: FixedBudget,
    title: "Fixed Budget",
    point1:
      "A fixed budget Metaverse development technique ensures paying a lump sum amount to experts for metaverse development.",
    point2: "Pay only for quality, not quantity",
    point3: "Focused pricing policy",
    point4: "There is no add-on cost",
    point5: "High working standards",
  },
  {
    icon: Renting,
    title: "Renting Developers",
    point1:
      "Hire our expert Metavetse developers  at hourly rates to accomplish your project in no time.",
    point2: "Consultancy at every step",
    point3: "Pay per hour",
    point4: "Regular project updates",
    point5: "Value your time",
  },
  {
    icon: WhiteLable,
    title: "White label solution",
    point1:
      "We offer seamless metaverse development services , with platforms created instantly to be launched on all app stores.",
    point2: "Tailor-made solution",
    point3: "Delivery before deadline",
    point4: "Cost-effective solutions",
    point5: "Real-time update",
  },
];
function MultiEngagement() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5 mb-5">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5">
          <h1
            className="Text-heading text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
           Metaverse Engagement Models
          </h1>
          <p
            className="text-detail text-center mt-3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            We are the best metaverse development company and ensure that our
            services are inclined towards uplifting businesses to their maximum
            potential. We have multiple engagement models:
          </p>
        </div>
        <div className="row justify-content-between mobile-responsive align-items-center">
          <div className="col-lg-12 col-md-12 mb-5">
            <div className="row ">
              {data.map((data) => {
                return (
                  <>
                    <div
                      className="col-lg-4 col-md-12 col-sm-12 mt-4"
                      data-aos="flip-right"
                      data-aos-duration="1000"
                    >
                      <div className="card " style={{ height: "100%" }}>
                        <div className="mt-3 text-center">
                          <img
                            src={data.icon}
                            className="img-fluid"
                            alt=""
                            width={"100px"}
                          />
                        </div>
                        <div className="text-left m-4">
                          <h3 className="">{data.title}</h3>
                          <h6 className=" mt-4">{data.point1}</h6>
                          <ul className="mt-4">
                            <li>{data.point2}</li>
                            <hr />
                            <li>{data.point3}</li>
                            <hr />
                            <li>{data.point4}</li>
                            <hr />
                            <li>{data.point5}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiEngagement;
