import React from "react";
const data = [
  {
    no: "01",
    title: "Data Accuracy",
    description:
      "Our artificial intelligence (AI) development services   ensure that insights are obtained from high-quality data. Our comprehensive data validation is the foundation of our services.",
  },
  {
    no: "02",
    title: "Explainability",
    description:
      " White-box AI models are used by our AI engineers   to help people comprehend AI algorithms and decision-making processes.",
  },
  {
    no: "03",
    title: "ROIs that can be anticipated",
    description:
      "Our AI development services   ensure that the proof-of-concept allows organisations to unlock the real potential of AI without making large upfront expenditures.",
  },
  {
    no: "04",
    title: "Security",
    description:
      "Our experienced AI developers   ensure that your data and infrastructure are constantly safeguarded by a strong security process.",
  },
];

function Roots() {
  return (
    <div className="development-bg">
      <div className="container">
        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-lg-8 col-md-12 col-sm-12 mt-5">
            <h1
              className="Text-heading text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
          AI Development Foundation
            </h1>
            <p
              className="mt-2 text-center text-detail"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              As the best AI development firm our team will help you expand
              your business quickly and be a part of your transformation path.
            </p>
          </div>
          <div className="col-lg-12 col-md-12 mt-3">
            <div className="row justify-content-around">
              {data.map((data) => {
                return (
                  <>
                    <div className="col-lg-5 col-md-10 col-sm-11 mt-3 mb-3">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-3 ">
                          <h1 className="text-center text-number">{data.no}</h1>
                        </div>
                        <div className="col-md-9">
                          <h1 className="top-heading">{data.title}</h1>
                          <p className="text-detail">{data.description}</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roots;
