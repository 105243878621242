import React from "react";
const data = [
  {
    title: "AI Softwaare Production",
    detail:
      "With our AI development services   you can predict client behaviour reduce manual data entry and increase data monitoring.",
  },
  {
    title: "AI Mobile App Production",
    detail:
      "Through secure AI mobile app development our AI development services   will enhance the user experience analyse data and do much more.",
  },
  {
    title: "AI Chatbot Creation",
    detail:
      "AI may be used to automate tasks. Chatbots are created by   most known AI development company to automate operations such as sales, marketing and customer service.",
  },
  {
    title: "Software for facial recognition",
    detail:
      "Our AI developers  may use face recognition technology to detect repeat customers and present them with deals resulting in strong customer loyalty.",
  },
  {
    title: "Learning Machines",
    detail:
      "With our AI development services   you can predict client behaviour reduce manual data entry and increase data monitoring.",
  },
  {
    title: "Advanced Learning",
    detail:
      "Our AI development services   may assist firms in automating predictive analysis and identifying the most recent consumer trends.",
  },
  {
    title: "Services for Business Intelligence",
    detail:
      "Through our powerful AI development services   you can design build and implement enterprise-level processes as well as manage platforms.",
  },
  {
    title: "Automation of Robotic Processes",
    detail:
      "The finest AI development company may employee RPA for a variety of supply chain tasks such as predictive maintenance and data input.",
  },
  {
    title: "Natural language processing",
    detail:
      "Our AI development services   may assist organisations in using NLP and improving customer service by interpreting spoken and written texts.",
  },
];
function AiDevvelopment() {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center align-items-center ">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5">
          <h1
            className="Text-heading text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            AI Connect Innovate And Inspire
          </h1>
          <p
            className="text-detail mt-3 text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            We provide AI development services in   to assist businesses in
            increasing their revenue through sustainability and innovation. Here
            are our smooth AI development solutions that may propel your
            company forward.
          </p>
        </div>
        <div className="row justify-content-between align-items-center mt-5 mb-5">
          {data.map((data) => {
            return (
              <>
                <div
                  className="col-lg-3 col-md-5 col-sm-12 border-gradient mt-5"
                  data-aos="flip-right"
                  data-aos-duration="1000"
                >
                  <div className="m-3">
                    <h1 className="text-title ">{data.title}</h1>
                    <p className="text-detail" style={{ fontSize: "15px" }}>
                      {data.detail}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AiDevvelopment;
