import React from "react";
import Insurance from "../../assets/Image/issurance.jpg";
import Hospitality from "../../assets/Image/hospitality.jpg";
import Construction from "../../assets/Image/construction.jpg";
import Sports from "../../assets/Image/sports.jpg";

const data = [
  {
    image: Insurance,
    title: "Solutions for Insurance Software",
    describtion:
      "Gain strategic insights and a competitive edge by developing insurance software.",
  },
  {
    image: Hospitality,
    title: "Solutions for Hospitality Software",
    describtion:
      "Disruptive approaches to actively influencing visitor needs and customer experiences.",
  },
  {
    image: Construction,
    title: "Solutions for Construction Software",
    describtion:
      "Accelerated digitization is combined with engineering development to address industry-wide issues.",
  },
  {
    image: Sports,
    title: "Solutions for Sports Software",
    describtion:
      "From team management to streaming e-sports solutions. We offer software for the sports business.",
  },
];

function Industries() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5 mb-5 ">
          <h1
            className="Text-heading text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
           Custom Software Industries
          </h1>
          <p
            className="text-detail text-center mt-2"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Virtual Tech a leading bespoke software development company provides
            technological knowledge to many industry sectors based on the client
            needs.
          </p>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-5 mb-5">
          <div className="row justify-content-between mobile-resposive">
            {data.map((data) => {
              return (
                <>
                  <div
                    class="col-lg-3  col-md-12 mt-4"
                    data-aos="flip-right"
                    data-aos-duration="1000"
                  >
                    <div class="card " style={{ height: "100%" }}>
                      <img
                        src={data.image}
                        class="card-img-top"
                        alt="..."
                        width={"80%"}
                      />
                      <div class="card-body ">
                        <h3 class="card-title mt-3">{data.title}</h3>

                        <p class="card-text  mt-3 ">{data.describtion}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Industries;
