import React from "react";
import underline from "../../assets/Image/UnderLine-01.svg";
import virtualSwap from "../../assets/logo/NewCoin-01.png";
import VirtualSwapUi from "../../assets/Image/virtualswap-01.png";
import VirtualExhangeLogo from "../../assets/logo/final 1.png";
import VirtualExchangeUi from "../../assets/Image/virtualExhange.png";
import VirtualCopLogo from "../../assets/logo/VirtualCopsLogoNew (1).png";
import VirtualCopsUI from "../../assets/Image/virtualCops-01.png";
import virtualLaunchPad from "../../assets/logo/virtualPad-logo.png";
import virtualLaunchPadUi from "../../assets/Image/virtualLaunchPad.png";
import virrtualcafeLogo from "../../assets/logo/virtualCafe.png";
import virtualcafeUi from "../../assets/Image/virtualcafe.png";

// import ComingSoon from "../../assets/Coming Soon/comingsoon.png";

import "./portfolio.css";

const Portfolio = () => {
  const data = [
    {
      brandImage: virtualSwap,
      brandHead: "virtual swap",
      brandIntro: "",
      brandPara:
        "The all-in-one blockchain solution for liquidity, swapping, farming, NFTs, staking and marketplace. Join us to experience seamless transactions, earn rewards, and explore the limitless possibilities of decentralized finance and digital ownership. Shape the future of blockchain with Virtual Swap today.",
      columnImage: VirtualSwapUi,
    },
    {
      brandImage: VirtualExhangeLogo,
      brandHead: "virtual exchange",
      brandPara:
        "Introducing Virtual Exchange, the next-generation Centralized Exchange (CEX) that empowers you to trade digital assets with ease. Enjoy a seamless trading experience, top-notch security and a user-friendly interface all in one centralized platform. Join Virtual Exchange today and unlock endless possibilities in the world of cryptocurrency trading.",
      columnImage: VirtualExchangeUi,
    },
    {
      brandImage: VirtualCopLogo,
      brandHead: "virtual Cops game",
      brandPara:
        "Welcome to Virtual Cops, the exciting web3-based game where you can immerse yourself in a virtual world while earning VRC tokens as rewards! Explore, compete and conquer challenges to level up your gameplay and build your digital empire. Get ready to unleash your skills and be rewarded with the ultimate gaming experience.",
      columnImage: VirtualCopsUI,
    },
    {
      brandImage: virtualLaunchPad,
      brandHead: "virtual launchpad",
      brandPara:
        "Welcome to Virtual Launch Pad, the premier launch pad platform for effortlessly listing your tokens. Our cutting-edge technology, user-friendly interface, and seamless integration make showcasing your tokens a breeze. Step into the future of token launches and maximize your success with Virtual Launch Pad.",
      columnImage: virtualLaunchPadUi,
    },
    {
      brandImage: virrtualcafeLogo,
      brandHead: "virtual Cafe",
      brandPara:
        "Introducing Virtual Cafe, the ultimate web3 platform where friends can gather in a virtual space, enjoy immersive games, indulge in movie nights and explore endless entertainment possibilities.  Step into a new dimension of socializing and fun like never before!",
      columnImage: virtualcafeUi,
    },
  ];

  return (
    <>
      <div className="container ">
        <div className="row justify-content-center top-margin mt-5 mb-5">
          <div className="Text-heading dis text-center mt-5 mb-4">
            <h2
              className="Text-heading text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Our Portfolio
            </h2>
            <img
              src={underline}
              alt="Heading Underlined"
              className="img-fluid"
              width={"30%"}
              data-aos="fade-up"
            />
          </div>
          <div className="col mt-5 mt-5 mb-5">
            {data.map((data) => {
              return (
                <>
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 card-border mt-5"
                    data-aos="flip-right"
                    data-aos-duration="1000"
                  >
                    <div className="row justify-content-center align-items-center">
                      <div className="col-lg-7 col-md-12 col-sm-12 protfolio-responsive">
                        <div className="d-flex flex-row align-items-center mt-5 mb-5 bd-highlight">
                          <div className="p-2 ">
                            <img
                              src={data.brandImage}
                              className="img-fluid"
                              width={"70px"}
                              alt=""
                            />
                          </div>
                          <div className="p-2 Text-title">{data.brandHead}</div>
                        </div>
                        <div className="">
                          <p className="text-detail text-portfolio ms-4 mt-3">
                            {data.brandPara}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-12 col-sm-12  ">
                        <div className="text-center m-2">
                          <img
                            src={data.columnImage}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            ;
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
