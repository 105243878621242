import React from "react";
import Ofline from "../../assets/Icon/offLine-01.svg";
import DataSecurity from "../../assets/Icon/dataSecurity-01.svg";
import User from "../../assets/Icon/userExperimence-01.svg";
import TimeReduced from "../../assets/Icon/TimeReduced-01.svg";
import Design from "../../assets/Icon/uiUx-01.svg";
import Integration from "../../assets/Icon/Integrationg-01.svg";

const data = [
  {
    icon: Ofline,
    title: "Offline Accessibility",
    detail:
      "Our famous mobile app development services   will design feature-rich apps that will be accessible to consumers even when they are not connected to the internet.",
  },

  {
    icon: DataSecurity,
    title: "Data Security",
    detail:
      "Our prestigious mobile application development services   will provide great data security by using only authorised APIs and encrypting all data.",
  },

  {
    icon: User,
    title: "Improved user experience",
    detail:
      "The direct chat function and in-app communication tool will collect client input directly and give users the best user experience possible.",
  },
  {
    icon: TimeReduced,
    title: "Loading time is reduced",
    detail:
      "Collaborate with the top mobile app development firm   to design applications that are bug-free and have a faster loading time thanks to extensive testing.",
  },
  {
    icon: Design,
    title: "Designing better user interfaces",
    detail:
      "Our mobile app development services will create an app with a well-designed UI and UX that is ideally optimised for a tiny touchscreen.",
  },
  {
    icon: Integration,
    title: "Integration of Data",
    detail:
      "Our top-tier mobile app developers  produce apps that securely connect your data to other platforms allowing you to utilise it and increase your ROI.",
  },
];

function NextGen() {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5 mb-5">
          <h5 className="text-center Text-heading" data-aos="fade-up">
          Next-Gen App Features
          </h5>
          <p className="text-center text-detail mt-3" data-aos="fade-up">
            Let us go through some low-code features that the top mobile app
            developers have included in your mobile app.
          </p>
        </div>
        <div className="row justify-content-around align-items-center">
          {data.map((data, index) => {
            return (
              <>
                <div
                  key={index}
                  className="col-lg-3 col-md-5 col-sm-12 border-gradient mt-5"
                  style={{ height: "270px" }}
                  data-aos="flip-left"
                  data-aos-delay="1000"
                >
                  <div className="m-3">
                    <div className="text-center mb-3">
                      <img
                        src={data.icon}
                        className="img-fluid"
                        alt=""
                        width={"100px"}
                      />
                    </div>
                    <h3 className="text-title">{data.title}</h3>
                    <p className=" text-detail">{data.detail}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default NextGen;
