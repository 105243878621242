import React from "react";
import MetaverseOne from "../../assets/Icon/metaverse1-01.svg";
import MetaverseTwo from "../../assets/Icon/metaverse2-01.svg";
import MetaverseThree from "../../assets/Icon/metaverse3-01.svg";
import MetaverseFour from "../../assets/Icon/metaverse4-01.svg";
import MetaverseFive from "../../assets/Icon/metaverse5-01.svg";
import MetaverseSix from "../../assets/Icon/metaverse6-01.svg";
import MetaverseSeven from "../../assets/Icon/metaverse7-01.svg";

import MetaverseEight from "../../assets/Icon/metaverse8-01.svg";

import MetaverseNine from "../../assets/Icon/metaverse9-01.svg";

import MetaverseTen from "../../assets/Icon/metaverse10-01.svg";

import MetaverseEleven from "../../assets/Icon/metaverse11-01.svg";
import MetaverseTwele from "../../assets/Icon/metaverse12-01.svg";

const data = [
  {
    icon: MetaverseOne,
    title: "Metaverse Game Development",
    detail:
      "Empower Your Gaming journey. Our  Metaverse developers will launch an ideal virtual game with a P2E gaming model, enabling gamers to monetize their skills and generate income.",
  },
  {
    icon: MetaverseTwo,
    title: "Real Estate Metaverse Platform Development",
    detail:
      "Our prestigious Metaverse development company  creates virtual estates and recreational areas, enhancing your offerings and captivating clients with immersive digital experiences. Solidify your presence in the virtual realm and redefine the real estate industry.",
  },
  {
    icon: MetaverseThree,
    title: "Metaverse NFT Development",
    detail:
      "Our Metaverse developers specialize in creating NFT-powered platforms where users can buy, sell and own digital assets. Harness the potential of NFTs to provide a unique and engaging experience for your users revolutionizing ownership and trading in the virtual world.",
  },
  {
    icon: MetaverseFour,
    title: "Metaverse Social Media Platform Development",
    detail:
      "Metaverse Development Company creates the ultimate 3D social media platform, redefining immersive experiences and connecting users like never before. Step into a new era of social interaction with our unrivaled expertise.",
  },
  {
    icon: MetaverseFive,
    title: "Metaverse NFT Marketplace Development",
    detail:
      "Unlock the NFT Revolution:Metaverse developers will create an exceptional NFT Marketplace, providing a premier platform for buying, selling and bidding on rare and valuable NFTs. Join the digital art and collectibles revolution with our cutting-edge solutions.",
  },
  {
    icon: MetaverseSix,
    title: "Metaverse App Development Services",
    detail:
      "Metaverse developers can bring your vision to life with captivating Metaverse apps spanning games, education, healthcare and real estate sectors. Step into the future and revolutionize your industry with our transformative solutions.",
  },
  {
    icon: MetaverseSeven,

    title: "Metaverse Event Platform Development",
    detail:
      "Harness the expertise of   premier Metaverse development company to host immersive virtual events such as seminars, concerts, weddings, award shows and more. Revolutionize event experiences and captivate attendees in the digital realm.",
  },
  {
    icon: MetaverseEight,

    title: "Metaverse Virtual Office Development",
    detail:
      "Partner with   leading Metaverse development company to create your own virtual office in the Metaverse, attracting customers from around the world. Leverage the power of immersive digital spaces to expand your reach and drive business growth.",
  },
  {
    icon: MetaverseNine,

    title: "Metaverse Education Platform Development",
    detail:
      "Metaverse development company pioneers immersive education platforms on the Metaverse, revolutionizing teaching and providing students with an engaging and interactive learning experience. Explore the future of education with our innovative solutions.",
  },
  {
    icon: MetaverseTen,

    title: "Metaverse Marketing Services",
    detail:
      " Metaverse developers will guide you in leveraging the power of the Metaverse as a marketing genius, unlocking new avenues for revenue growth and captivating your target audience. Step into the future of marketing and redefine your business success.",
  },
  {
    icon: MetaverseEleven,

    title: "Metaverse Integration Services",
    detail:
      "Our Metaverse developers will help several businesses in boosting their business sales by customizing your Metaverse platform as well as augmenting it.",
  },
  {
    icon: MetaverseTwele,

    title: "Metaverse Banking",
    detail:
      "Leverage superior Fintech services by the leading Metaverse development company and leverage the potential of cryptocurrency.",
  },
];

function NextGenMetaverse() {
  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-10 col-md-12 col-sm-12 mt-5 mb-5">
          <h1
            className="text-center Text-heading"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
              Leading Metaverse Unlockers
          </h1>
          <p
            className="text-center text-detail mt-2"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Ignite Metaverse Revolution: Leading Provider of Next-Gen
            Metaverse Solutions. Experience immersive design, customizable
            avatars, tokenized assets, seamless transactions, cross-platform
            deployment, robust security, and vibrant social interactions.
          </p>
        </div>
        <div className="row justify-content-around align-items-center">
          {data.map((data) => {
            return (
              <>
                <div
                  className="col-lg-3 col-md-5 col-sm-12 border-gradient mt-5 "
                  style={{ height: "330px" }}
                  data-aos="flip-left"
                  data-aos-duration="1000"
                >
                  <div className=" m-2">
                    <div className="mb-3 text-center">
                      <img
                        src={data.icon}
                        className="img-fluid"
                        alt=""
                        width={"120px"}
                      />
                    </div>

                    <h3 className="text-title">{data.title}</h3>
                    <p className="text-detail mt-3">{data.detail}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default NextGenMetaverse;
