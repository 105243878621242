import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./page/home";
import Portfolio from "./page/portfolio/portfolio";
import NavbaaComp from "../src/component/header/navbar";
import Footer from "../src/component/footer/footer";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Contact from "./page/contact/contact";
import Web3 from "./page/web3/web3";
import Metaverse from "./page/metaverse/metaverse";
import Application from "./page/app/app";
import Software from "./page/software/software";
import AI from "./page/ai/ai";
import IOT from "./page/iot/iot";
import ChatBot from "./page/chatBot/chatBot";
import AboutUs from "./page/aboutus/aboutUs";
import Privacy from "./component/privacy/privacy";
import { useEffect } from "react";
import { useState } from "react";

function App() {
  let [show, setIshow] = useState(false);
  const location = useLocation();
  useEffect(() => {
    let location = window.location;
    let splitLocation = location.href.split("/");
    setIshow(splitLocation.includes("privacy"));
  }, [location.pathname]);
  return (
    <div className="App-bg">
      {!show && <NavbaaComp />}
      <Routes>
        <Route path="/privacy" element={<Privacy />} />

        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/web3" element={<Web3 />} />
        <Route path="/metaverse" element={<Metaverse />} />
        <Route path="/app" element={<Application />} />
        <Route path="/software" element={<Software />} />
        <Route path="/ai" element={<AI />} />
        <Route path="/iot" element={<IOT />} />
        <Route path="/chatbot" element={<ChatBot />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="*" element={<Home />} />
      </Routes>
      {!show && <Footer />}
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
