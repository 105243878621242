import React from "react";
import Object from "../../assets/Image/objectDetection.jpg";
import Models from "../models/model";
import { useState } from "react";
function ObjectDetection() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="container">
      <div className="row align-items-center ">
        <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5 object-responsive">
          <div data-aos="fade-up" data-aos-duration="1000">
            <img src={Object} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5 ">
          <h1
            className="Text-heading"
            style={{ fontSize: "15px" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Object Detection
          </h1>
          <h4
            className="Text-heading"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Object Detection Experts
          </h4>
          <p
            className="text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Use the top AI development services   to achieve accurate
            personalised object detection. We provide AI software that is
            tailored to your specific business requirements.
          </p>
          <p
            className=" text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Object detection may be used by businesses for a variety of
            applications including anomaly detection, video monitoring,
            self-driving automobiles and crowd-funding.
          </p>
          <div className="" data-aos="fade-up" data-aos-duration="1000">
            <button
              className="btn btn-experts p-3"
              onClick={() => setModalShow(true)}
            >
              Contact Us
            </button>
            <Models show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ObjectDetection;
