import React, {useEffect} from "react";
import Iot from "../../component/iot/index";

function IOT() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Iot />
    </>
  );
}

export default IOT;
