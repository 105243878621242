import React from "react";
import AppImg from "../../assets/Image/aPP.png";
import "./app.css";
import Models from "../models/model";
import { useState } from "react";

function Application() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="app-bg">
      <div className="container top-margin">
        <div className="row justify-content-center align-items-center ">
          <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5">
            <h1 className="Text-heading" data-aos="fade-up">
              Vertical Mobile Solutions
            </h1>
            <p className="text-detail mt-5" data-aos="fade-up">
              Unleash the power of cutting-edge technology with mobile
              app development company Virtual Tech. Our decade-long expertise
              and 250+ satisfied global clients testify our excellence in
              creating market-leading mobile apps.
            </p>
            <div>
              <button
                className="btn btn-experts p-3 mt-3"
                onClick={() => setModalShow(true)}
                data-aos="fade-up"
              >
                Get Started
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5">
            <div className="text-center">
              <img src={AppImg} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Application;
