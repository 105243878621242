import React from "react";
import "./ai.css";
import Models from "../models/model";
import { useState } from "react";

function Ai() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="ai-bg">
      <div className="container mt-5">
        <div className="row top-margin">
          <div className="col-lg-6 col-md-6 col-sm-12 text-center mt-5 mb-5">
            <h1 className="Text-heading" data-aos="fade-up">
              AI Development
            </h1>
            <p className="text-detail mt-2" data-aos="fade-up">
              We are the top artificial intelligence development firm and a
              respected provider of artificial intelligence solutions for small
              and large enterprises. Use artificial intelligence to get the
              greatest possible business results.
            </p>
            <div className="text-start mt-2" data-aos="fade-up">
              <button
                className="btn btn-experts p-3"
                onClick={() => setModalShow(true)}
              >
                Start Your Journey Now!
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ai;
