import React from "react";
import "./web3.css";
import Line from "../../assets/Image/UnderLine-01.svg";

function Web3Leading() {
  return (
    <div className="web3Leading-bg">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5">
            <h1
              className="Text-heading mt-5 text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Web3 Development Leaders
            </h1>
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={Line} className="img-fluid" alt="" width={"50%"} />
            </div>
            <p
              className="text-detail  text-center mt-3"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Discover the professional guidance you require to propel your
              business ahead. Elevate your enterprise with our pioneering and
              tech-centric web3 solutions.
            </p>
            <div
              className="text-center mb-3"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <button className="btn btn-experts p-3 ">
                Contact our experts
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Web3Leading;
