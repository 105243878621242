import React from "react";

function StrelingDevelopment() {
  return (
    <div className="Leverage-bg">
      <div className="container mt-5">
        <div className="row justify-content-center align-items-center mt-5 mb-5">
          <div className="col-lg-8 col-md-12 col-sm-12 mt-5 mb-5">
            <h1
              className="Text-heading text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
               Metaverse Experts Idea Realization
            </h1>
            <p
              className="text-center text-detail mt-2"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Virtual Tech is dedicated to makingthe most feasible IT
              hub on the planet, with cost-effective and transparent business
              solutions. We will be with you from the start to the end of your
              metaverse journey.
            </p>
            <div
              className="text-center mt-4"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <button className="btn btn-experts p-3">Contact Us Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StrelingDevelopment;
