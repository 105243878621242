import React from "react";
import Line from "../../assets/Image/UnderLine-01.svg";
// import party from "../../assets/Image/party.PNG"
const data = [
  {
    // pic: party,
    title: "Web3.0 Event Platform Development",
    detail:
      "Connect with people all over the world safely and easily using a web3 event platform that includes spatial UX design, 3D technology driven interactivity, data ownership and more.",
  },
  {
    // pic: party,
    title: "Web3.0 Social Media Platform Development",
    detail:
      "Create a web3 social media network that gives people more control over their content and information. Today, take use of the potential of NFT and blockchain-powered web3 social media.",
  },
  {
    // pic: party,
    title: "Web3.0 Real Estate Platform Development",
    detail:
      "Web3 can make the real estate sector more accessible. Using NFTs, provide property ownership, make listings public, and quickly scrutinise mortgages and loans.",
  },
  {
    // pic: party,
    title: "Development of a Web3.0 Ecommerce Store",
    detail:
      "Web-based e-commerce stores will revolutionise shopping. AR/VR technologies, voice assistance and an open source ecosystem with simple asset ownership are all integrated.",
  },
  {
    // pic: party,
    title: "Web3.0 Education Platform Development",
    detail:
      "Create better access to learning courses, build immersive training sessions and connect students with teachers from all across the globe with the Web3 education platform.",
  },
  {
    // pic: party,
    title: "Web3.0 Enterprise Level Solutions",
    detail:
      "Get enterprise-level solutions for Web3 development. Enhance security and improve accountability for each action within the business processes to grow further.",
  },
  {
    // pic: party,
    title: "Web3.0 Banking Solutions",
    detail:
      "Our Web3 banking solutions make virtual banking simple. Allow for the simple tokenization of assets while lowering expenses by improving the efficiency of day-to-day corporate activities.",
  },
  {
    // pic: party,
    title: "Development of a Web3.0 NFT Marketplace",
    detail:
      "We examine every area of your platform development and performance from blockchain integration to NFT minting and deployment.",
  },
  {
    // pic: party,
    title: "Setup of a Web3.0 Corporate Office",
    detail:
      "Connect with people all around the world in a secure and comfortable manner using a web3 corporate office platform that includes features such as live Q&A, screen sharing, gesture detection and more.",
  },
];

function Transformation() {
  return (
    <div className="container">
      <div className="row justify-content-center align items-center">
        <div
          className="col-lg-8 col-md-12 col-sm-12 mt-5 mb-5"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <h1 className="Text-heading text-center">
            Game-Changing Web3 Transformations
          </h1>
          <div className="text-center">
            <img src={Line} className="img-fluid" width={"40%"} alt="" />
          </div>
          <p className="text-center text-detail">
            Virtual Tech is capable of developing web3 solutions for any sector.
            Since 2011, we have served many business sectors. Our knowledge
            enables us to create and provide the best web3 development solutions
            .
          </p>
        </div>
        <div className="row justify-content-around">
          {data.map((data, index) => {
            return (
              <>
                <div
                  key={index}
                  className="col-lg-3 col-md-5 col-sm-12 border-gradient1 mt-4"
                  data-aos="fade-down-right"
                  data-aos-delay="1000"
                >
                  <div className=" m-3">
                    {/* <img src={pic} className="img-fluid" alt="" /> */}

                    <div>
                      <h4 className="text-title ">{data.title}</h4>
                      <p className="text-detail  mt-3">{data.detail}</p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Transformation;
