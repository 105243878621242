import React from "react";
import Line from "../../assets/Image/UnderLine-01.svg";
// import Line from "../../assets/Image/UnderLine-01.svg";

import Accordion from "react-bootstrap/Accordion";
const accordionData = [
  {
    title: "How long does it take to build a mobile app?",
    key: 1,
    detail:
      "The time frame spans from 3 to 12 months depending on the complexity and features to be added.",
  },
  {
    key: 2,
    title: "Describe the development steps you take.",
    detail:
      "We begin with analysing the requirements followed by wireframing and designing and lastly developing and testing the app. The app deployment is now complete. The support and maintenance phases follow.",
  },
  {
    key: 3,
    title: "Can we create a mobile app in one month?",
    detail:
      "No, because the app must go through a number of procedures and checks that take time.",
  },
];
const accordionDataTwo = [
  {
    key: 4,
    title: "What kinds of mobile apps do you create?",
    detail:
      "We create mobile applications for the Android and iOS operating systems.",
  },
  {
    key: 5,
    title: "Can I make changes to the app after it has been launched?",
    detail:
      "Depending on the adjustments you want to make our mobile app development services can assist you.",
  },
  
];

function FrequentlyAsked() {
  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5">
          <h1 className="Text-heading text-center">
            Frequently Asked Questions
          </h1>
        </div>
        <div className="col-lg-5 col-md-12 mt-3">
          <div className="text-center">
            <img src={Line} className="img-fluid" alt="" width={"70%"} />
          </div>
        </div>
        <div className="row justify-content-between mt-5 mb-5">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {accordionData.map((data) => {
              return (
                <>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body>{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            {accordionDataTwo.map((data) => {
              return (
                <>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body>{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrequentlyAsked;
