import React from "react";
import Software from "../../assets/Image/software.jpg";
import Models from "../models/model";
import { useState } from "react";

function LeverageCustom() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5 mb-5">
        <div
          className="col-lg-6 col-md-12 col-sm-12"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={Software} className="img-fluid" alt="" />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <h1
            className="Text-heading"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Custom Software Solutions
          </h1>
          <p
            className="text-detail mt-2"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Our firm specialises in the creation of specialised software for
            online and mobile platforms. From design through delivery and
            maintenance our bespoke software development services span the
            whole development cycle. Our software professionals closely follow
            business procedures while developing custom software solutions to
            provide a product that provides value to your organisation.
          </p>
          <div className="mt-3" data-aos="fade-up" data-aos-duration="1000">
            <button
              className="btn btn-experts p-3"
              onClick={() => setModalShow(true)}
            >
              Request Our Service
            </button>
            <Models show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeverageCustom;
