import React from "react";
import Line from "../../assets/Image/UnderLine-01.svg";
import Models from "../models/model";
import { useState } from "react";

function HireWeb3() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="hire-bg mt-5">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div
            className="col-lg-7 col-md-12 col-sm-12 mt-5 mb-5"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <h1 className="Text-heading text-center ">
            Web3 Future Talent
            </h1>
            <div className="text-center">
              <img src={Line} className="img-fluid" alt="" width={"40%"} />
            </div>
            <p className="text-detail text-center mt-2">
              With our web3 solutions created for you, discuss your concept and
              move forward.
            </p>
            <div className="text-center mt-2">
              <button
                className="btn btn-experts p-3"
                onClick={() => setModalShow(true)}
              >
                Talk To experts
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HireWeb3;
