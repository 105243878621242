import React from "react";
import Web3 from "./web3";
import Boots from "./boots";
import Web3Leading from "./web3Leading";
import Web3Development from "./web3Development";
import HireWeb3 from "./hireWeb3";
import Transformation from "./transformation";
import DevelopmentWeb3 from "./developmentWeb3";
import ServiceNdDevelopment from "./serviceNdDevelopment";
import WhyChoose from "./whyChoose";
import TechStack from "./techStack";
import Allindustries from "./allindustries";
import Frequentlyasked from "./frequentlyasked ";

function Index() {
  return (
    <>
      <Web3 />
      <Boots />
      <Web3Leading />
      <Web3Development />
      <HireWeb3 />
      <Transformation />
      <DevelopmentWeb3 />
      <ServiceNdDevelopment />
      <WhyChoose />
      {/* <TechStack /> */}
      <Allindustries />
      <Frequentlyasked />
    </>
  );
}

export default Index;
