import React from "react";
import TaxiIcon from "../../assets/Icon/taxiApp.png";
import Elearning from "../../assets/Icon/eLearning.png";
import FoodApp from "../../assets/Icon/FoodApp.png";
import healthCare from "../../assets/Icon/healthCare-01.svg";
import Transportation from "../../assets/Icon/Transportaion-01.svg";
import GameIcon from "../../assets/Icon/Game-01.svg";
import SocialMedia from "../../assets/Icon/socialmedia-01.svg";
import Entertaiment from "../../assets/Icon/entertainment-01.svg";
import Ecommerce from "../../assets/Icon/eCommerce-01.svg";
import Religous from "../../assets/Icon/Religous-01.svg";
import Payment from "../../assets/Icon/Payment-01.svg";
import Enterprise from "../../assets/Icon/Enterprise-01.svg";
const data = [
  {
    icon: TaxiIcon,
    title: "App for Booking Taxis",
    detail:
      "Use our skills in mobile application development services to build your ideal taxi app with the most cutting-edge technology and control the roads.",
  },
  {
    icon: Elearning,
    title: "App for E-Learning",
    detail:
      "Our mobile app developers will design the best education app to propel educational organisations to new heights with doubled ROIs in a couple of months.",
  },
  {
    icon: FoodApp,
    title: "App for Food",
    detail:
      "Our well-known mobile app development firm   is well-known for providing food and restaurant applications that may assist you in getting your meal delivered directly to your door.",
  },
  {
    icon: healthCare,
    title: "App for healthcare",
    detail:
      "Our mobile app development services will design an app that will alter the healthcare business by allowing users to see physicians remotely with the push of a button.",
  },
  {
    icon: Transportation,
    title: "App for logistics and transportation",
    detail:
      "With admin, shipper and carrier panels on-demand delivery apps that track logistics and transportation link shippers directly to vehicle drivers.",
  },
  {
    icon: GameIcon,
    title: "App for games",
    detail:
      "Use the top mobile app development firm   to get the best gaming experience. We will provide 2D and 3D game applications.",
  },
  {
    icon: SocialMedia,
    title: "App for social media",
    detail:
      "Hire the best social media mobile app development services to create an app with an easy-to-use UI. Create a community by connecting your users with their friends and family.",
  },
  {
    icon: Entertaiment,
    title: "App for entertainment",
    detail:
      "Our mobile app developers will create an entertainment app with streaming features that include audio, video and textual material. On your app you can watch sports, movies, music, podcasts and much more.",
  },
  {
    icon: Ecommerce,
    title: "App for E-Commerce",
    detail:
      "Our mobile application development services can produce an app that will add functionality to your e-commerce website. We make certain that your app has the most up-to-date features.",
  },
  {
    icon: Religous,
    title: "App for Religious Purposes",
    detail:
      "We can design a prayer app with features that allow users to meditate, read, listen to music and do other things. Our  mobile app developers will help with both IOS and Android app development.",
  },
  {
    icon: Payment,
    title: "Apps for mobile payments",
    detail:
      "Make use of the top mobile application development services   to construct a mobile payment with a user-friendly interface that supports P2P transactions and cryptocurrencies.",
  },
  {
    icon: Enterprise,
    title: "Enterprise-Specific Application",
    detail:
      "These are complicated, scalable and component-based programmes that will perform best in a complex work context such as the government or a scaled firm.",
  },
];

function MobileApp() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5 mb-5">
          <h1 className="text-center Text-heading">
            Smart Choice Mobile App Developers
          </h1>
          <p className="text-center text-detail">
            Accelerate Your Business with  Leading Mobile App Development
            experts, crafting smart, affordable and swift iOS and
            Android Solutions 24/7.
          </p>
        </div>
        <div className="row justify-content-around align-items-center">
          {data.map((data, index) => {
            return (
              <>
                <div
                  key={index}
                  className="col-lg-3 col-md-5 col-sm-12 border-gradient mt-5"
                  style={{ height: "280px" }}
                  data-aos="flip-right"
                  data-aos-delay="1000"
                >
                  <div className="m-3">
                   
                    <h1 className="text-title mt-5">{data.title}</h1>
                    <p className=" text-detail mt-3">{data.detail}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MobileApp;
