import React, { useState } from "react";
import "./virtualValley.css";
import UAE from "../../../assets/Image/uae.png";
import Models from "../../models/model";

function VirtualValley() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="container">
      <div className="row jusitfy-content-center align-items-center mt-5 mb-5 valley-bg mobile-responsive">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5 mb-5">
          <h2 className="text-Join">Embrace the Future:</h2>
          <h6 className="" style={{fontSize:"25px", margin:"30px", color:"white"}}>
            Join Virtual Tech  to Transforming the world into the Silicon
            Valley of Tomorrow!
          </h6>
          <div className="ms-4 button-responsive">
            <button
              className="btn btn-experts p-3"
              onClick={() => setModalShow(true)}
            >
              Join us Now
            </button>
            <Models show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
        <div className="col-lg-4 col-md-12 mt-5 mb-5">
          <div>
            <img src={UAE} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualValley;
