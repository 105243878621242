import React from "react";
import "./footer.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import VirtualLogo from "../../assets/logo/virtualLogo-01.png";

// import { FiTwitter } from "react-icons/fi";
const currentYear = new Date().getFullYear(); // 2020
const previousYear = currentYear - 1;

function Footer() {
  return (
    <div className="footer-bg mt-5">
      <div className="container mt-5">
        <div className="row justify-content-between m-auto  mt-5">
          <div className="col-lg-4 col-md-12 col-sm-12 mt-3 ">
            <div>
              <img
                src={VirtualLogo}
                className="img-fluid"
                alt=""
                width={"40%"}
              />
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12 col-sm-12 mt-3 ">
            <div className="">
              <h1 className="footer-title">Quick Links:</h1>
            </div>
            <div>
              <ul
                className="footer-list "
                style={{
                  fontSize: "12px",
                  listStyle: "none",
                  marginLeft: "-25px",
                }}
              >
                <li>
                  <Link to="/web3" className="footer-link">
                    Web3
                  </Link>
                </li>
                <li>
                  <Link to="/metaverse" className="footer-link">
                    Metaverse
                  </Link>
                </li>
                <li>
                  <Link to="/app" className="footer-link">
                    App
                  </Link>
                </li>
                <li>
                  <Link to="/software" className="footer-link">
                    Software
                  </Link>
                </li>
                <li>
                  <Link to="/ai" className="footer-link">
                    AI
                  </Link>
                </li>
                <li>
                  <Link to="/iot" className="footer-link">
                    IoT
                  </Link>
                </li>
                <li>
                  <Link to="/chatbot" className="footer-link">
                    Chatbot
                  </Link>
                </li>
                <li>
                  <Link to="/portfolio" className="footer-link">
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link to="/aboutus" className="footer-link">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contactus" className="footer-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-lg-4 col-md-12 col-sm-12 mt-3 ">
            <div className="">
              <h1 className="footer-title">Contact</h1>
            </div>
            <div>
              <h2 className="footer-heading">
                <span className="" style={{ fontSize: "18px" }}>
                  <BsFillTelephoneFill /> &nbsp;
                </span>
                Phone:
              </h2>
              <p className="footer-detail">+97-150-994-9375</p>
              <h2 className="footer-heading">
                <span className="" style={{ fontSize: "18px" }}>
                  <MdEmail /> &nbsp;
                </span>
                Email:
              </h2>
              <p className="footer-detail">info@virtualtec.org</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 mt-3 ">
            <div className="">
              <h1 className="footer-title">Address</h1>
            </div>
            <div>
              <h2 className="footer-heading">
                <span className="" style={{ fontSize: "18px" }}>
                  <ImLocation /> &nbsp;
                </span>
                Office No. 702, Al Saud Building 2 - Al Qusais Industrial Area 4
                - Dubai - United Arab Emirates
              </h2>
              <iframe
                className="mt-3"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3607.3926056502296!2d55.40081921501145!3d25.291010183852652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDE3JzI3LjYiTiA1NcKwMjQnMTAuOCJF!5e0!3m2!1sen!2s!4v1684765418673!5m2!1sen!2s"
                width={"100%"}
                height={"200"}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <hr style={{ color: "#fff" }} />
          </div>
          <div className="col-lg-6 col-md-8 col-sm-12 ">
            <p className="text-center text-copyRight">
              Copyright {previousYear}-{currentYear} by VirtualTech.All Rights
              Reserved.
            </p>
          </div>
          <div className="col-lg-6 col-md-8 col-sm-12 mb-3 ">
            <div className="d-flex justify-content-center gap-3">
              <a
                href="https://www.facebook.com/VirtualtechVision/"
                className="icon_bg"
                target={"_blank"}
              >
                <FaFacebookF />
              </a>

              <a
                href="https://www.instagram.com/virtualtechvision/"
                className="icon_bg"
                target={"_blank"}
              >
                <FaInstagram />
              </a>

              <a
                href="https://www.linkedin.com/company/virtualtechub/"
                target={"_blank"}
                className="icon_bg"
              >
                <FaLinkedinIn />
              </a>
              <Link
                to="privacy"
                // target={"_blank"}
                className="icon_bg"
              >
                <span>Privacy</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
