import React from "react";
import Line from "../../assets/Image/UnderLine-01.svg";
import Web3Pic from "../../assets/Image/Web3-01.png";

function Boots() {
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div
            className="col-lg-8 col-md-12 col-sm-12 mt-5"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <h2 className="Text-heading text-center mt-5">
              Web3 Business Boost
            </h2>
            <div className="text-center">
              <img src={Line} className="img-fluid" alt="" width={"30%"} />
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 mt-5"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="text-center">
              <img src={Web3Pic} className="img-fluid" width={"60%"} />
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 mt-5 "
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="">
              <p
                className="text-detail mt-3"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                Experience the power of web3, revolutionizing the limitations of
                Web2. With enhanced accessibility, data ownership, and
                heightened security, web3 holds immense potential. Join the
                paradigm shift with Virtual Tech, a leading web3 development
                service provider. Our skilled web3 developers excel in
                blockchain, AR/VR and AI, delivering tailored solutions for
                diverse industries like healthcare, education and finance.
                Elevate your business with our innovative web3 solutions.
                Partner with us today to shape your future in the world of web3.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Boots;
