import React from "react";
import { useState } from "react";

// import Picture from "../../../assets/Image/blockchain-v1.png";
import Picture from "../../../assets/gif/Webgife.gif";
import Models from "../../models/model";
import "./hero.css";
// import "./App.css"

function HeroSection() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className=" top-margin">
      <div className="bg-gradient mt-5"></div>
      <div className="container">
        <div className="row justify-content-center align-items-center  ">
          <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5 hero-section">
            <h1
              className="Text-heading mb-3 "
              // data-aos-delay="400"
            >
              Discover Our Blockchain Development Hub
            </h1>
            <p
              className="text-detail mt-3 mb-3"

              // data-aos-delay="400"
            >
              We are in the business of developing world quality
              blockchain-based development services that are readily accessible
              and highly affordable. Get in touch with us and enrich your
              business with expertise.
            </p>
            <div className="d-flex justify-content-md-start btn-responsive">
              <button
                className="btn btn-experts p-3"
                onClick={() => setModalShow(true)}
              >
                Talk To Our Experts
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5  hero-section Picture-responsive">
            <div>
              <img src={Picture} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
