import React from "react";
import Chatbot from "./chatbot";
import NextGen from "./nextGen";
import Optimization from "./optimization";
import Advantage from "./advantage";
import RapidChatbot from "./rapidChatbot";
import Concentrate from "./concentrate";
import ChatbotDevelopment from "./chatbotDevelopment";
import JoinCompany from "./joinCompany";
import FrequentlyAsked from "./frequentlyAsked";

function Index() {
  return (
    <>
      <Chatbot />
      <NextGen />
      <Optimization />
      <Advantage />
      <RapidChatbot />
      <Concentrate />
      <ChatbotDevelopment />
      <JoinCompany />
      <FrequentlyAsked />
    </>
  );
}

export default Index;
