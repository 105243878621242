import React from "react";

const data = [
  {
    title: "Dedicated Group",
    detail:
      "Our professional bespoke software development team has years of expertise in providing cutting-edge software solutions and new features to our clients.",
  },
  {
    title: "Transparency in Pricing",
    detail:
      "To deliver affordably priced business-oriented software solutions Virtual Tech adheres to an open pricing structure and software development approach.",
  },
  {
    title: "Meaningful Outcomes",
    detail:
      "We understand the client organisation before building specialised software solutions that address urgent difficulties and deliver big outcomes.",
  },
  {
    title: "Hiring Flexibility",
    detail:
      "Our professional bespoke software development team has years of expertise in providing cutting-edge software solutions and new features to our clients.",
  },
];
function VirtualSoftware() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5">
        <div className="col-lg-8 col-md-12 col-sm-12">
          <h1
            className="text-center Text-heading mt-5"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Virtual Tech Custom Software Specialists
          </h1>
          <p
            className="text-center text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            With over 6+ years of expertise in digitising company operations, we
            are a trustworthy offshore bespoke software development partner. We
            have repeatedly been rated the most preferred software engineering
            firm due to our incredibly collaborative approach.
          </p>
        </div>
        <div className="row justify-content-between align-items-center mobile-responsive mt-5 mb-5">
          {data.map((data) => {
            return (
              <>
                <div
                  className="col-lg-3 col-md-5 col-sm-12 border-card mt-3"
                  style={{ height: "210px" }}
                  data-aos="flip-left"
                  data-aos-duration="1000"
                >
                  <div className="m-3">
                    <h1 className=" text-title">
                      <b>{data.title}</b>
                    </h1>
                    <p className=" text-detail mt-2">{data.detail}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default VirtualSoftware;
