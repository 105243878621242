import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Line from "../../assets/Image/UnderLine-01.svg";

const accordionData = [
  {
    title: "Which language is the best for IoT?",
    key: 1,
    detail:
      "Python is the best language for IoT programming as many already know how to use it.",
  },
  {
    key: 2,
    title: "Is IoT in high demand in the Middle East?",
    detail:
      "The IoT strategy has already been in the news, unifying data sources and opening up new pathways for digital growth.",
  },
];


function FrequentlyAsked() {
  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5">
          <h1 className="Text-heading text-center">
            Frequently Asked Questions
          </h1>
        </div>
        <div className="col-lg-5 col-md-12 mt-3">
          <div className="text-center">
            <img src={Line} className="img-fluid" alt="" width={"70%"} />
          </div>
        </div>
        <div className="row justify-content-center mt-5 mb-5">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {accordionData.map((data) => {
              return (
                <>
                <div>
                  <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body>{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                  </div>
                </>
              );
            })}
          </div>
         
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default FrequentlyAsked;
