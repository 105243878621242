import React, {useEffect} from "react";
import AboutUsComp from "../../component/aboutus/index";
function AboutUs() {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <AboutUsComp />
    </>
  );
}
export default AboutUs;
