import React from "react";

function PremiumAi() {
  return (
    <div className="container">
      <div className="row justify-content-around ">
        <div className="col-lg-3 col-md-6 col-sm-12 mt-5 ">
          <h1
            className="text-center Text-heading"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
              Premier AI Services
          </h1>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 mt-5">
          <p
            className="text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Our experienced AI developers can assist organisations in rapidly
            increasing their ROI.AI is a value-added solution for organisations today
            providing the opportunity for them to react quickly and get insights
            for innovation and competitive advantage.
          </p>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 mt-5">
          <p
            className="text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            AI development services are already dominating 21st-century
            industries and  world is on its way to becoming a futuristic centre
            of cutting-edge technology like AI and machine learning.
          </p>
        </div>
        <div className="col-lg-10 col-md-12 col-sm-12 mt-2">
          <p
            className="text-detail text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Our AI developers will create a market-leading product that will
            keep your company ahead of the competition while also making it a
            market brand.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PremiumAi;
