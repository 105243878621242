import React from "react";
import "./web3.css";
import Line from "../../assets/Image/UnderLine-01.svg";
const data = [
  {
    title: "Internally trained developers",
    detail:
      "Virtual Tech employs skilled web3 developers that have been internally educated to meet our high standards of quality and consistency in every project.",
  },
  {
    title: "Expertise in Web3",
    detail:
      "Our experience spans all aspects of web3 development from smart contracts to a full web3 platform for your company.",
  },
  {
    title: "Extensive project experience",
    detail:
      "We design anything from NFT marketplaces and web3 games to web3 social media and streaming services.",
  },
];

function WhyChoose() {
  return (
    <div className="why-bg">
      <div className="container mt-5 mb-5">
        <div className="row justify-content-center align-items-center">
          <div
            className="col-lg-7 col-md-8 col-sm-12 mt-5 mb-5"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h1 className="Text-heading text-center">
              Web3 Experts at Virtual Tech
            </h1>
            <div className="text-center mt-2">
              <img src={Line} className="img-fluid" width="40%" />
            </div>
            <p className="text-detail text-center mt-2">
              Only the best team can deliver the best performance. Virtual Tech
               provides you with access to a team of professional Web3
              development experts. Their expertise in cutting-edge technology
              enables them to effectively meet any problem.
            </p>
          </div>

          <div className="row justify-content-around align-items-center">
            {data.map((data) => {
              return (
                <>
                  <div
                    className="col-lg-3 col-md-5 col-sm-12 border-gradient mt-3 mb-3"
                    data-aos="fade-up"
                    style={{ height: "200px" }}
                    data-aos-duration="1000"
                  >
                    <div className="m-4">
                      <h4 className="text-title">{data.title}</h4>
                      <p className=" text-detail mt-2">{data.detail}</p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChoose;
