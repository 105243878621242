import React from "react";
import "./aboutintro.css";
import Line from "../../assets/Image/UnderLine-01.svg";

function About() {
  return (
    <div className="about-bg">
      <div className="container top-margin">
        <div className="row justify-content-center ">
          <div className="col-lg-12 col-md-12 mt-5 mb-5">
            <h1 className="Text-heading text-center mt-5" data-aos="fade-up">About Us</h1>
            <div className="text-center mb-5" data-aos="fade-up">
              <img src={Line} className="img-fluid" width={"30%"} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
