import React from "react";
import ImageOne from "../../assets/create market/enterprise.png";
import ImageTwo from "../../assets/create market/marketplace.png";
import ImageThree from "../../assets/create market/Metaverse.png";
import ImageFour from "../../assets/create market/walletdevelopment.png";
import ImageFive from "../../assets/create market/P2E.png";
import ImageSix from "../../assets/create market/etherum.png";
import ImageSeven from "../../assets/create market/smartContract.png";
import ImageEight from "../../assets/create market/cloud.png";
import ImageNine from "../../assets/create market/blockchain-security.png";

const data = [
  {
    image: ImageOne,
    title: "AI-Powered Chatbots",
    description:
      "Contact our artificial intelligence development firm   to equip your organisation with an AI-based chatbot that will enable personalised interactions improving loyalty and revenues.",
  },
  {
    image: ImageTwo,
    title: "AI-Based Contact Centre",
    description:
      "Reach out to more consumers across numerous channels with our AI development services  . While harnessing real-time conversation intelligence and improving your service call.",
  },
  {
    image: ImageThree,
    title: "Speech and Voice Recognition ",
    description:
      "Recognise speech with our top-tier AI development services and respond to consumers with precise orders and no human interaction.",
  },
  {
    image: ImageFour,
    title: "Document Recognition Software ",
    description:
      "Choose the top artificial intelligence development firm  , Virtual Tech to assist your clients in identifying their files and extracting data to reduce data processing time.",
  },
  {
    image: ImageFive,
    title: "Computer Vision and Object Location",
    description:
      "Allow AI tools to recognise things and collect information from videos and photographs to help you make better decisions with our seamless AI development services  .",
  },
  {
    image: ImageSix,
    title: "Digital Assistance",
    description:
      "By employing the finest AI development services   and their AI Software. You may provide 24*7 digital help to all of your clients significantly improving the customer experience.",
  },
  {
    image: ImageSeven,
    title: "Compliance with Regulations ",
    description:
      "Through our seamless AI development services you may allow your firm to expand under the control of country-specific legislation from the authorities.",
  },
  {
    image: ImageEight,
    title: "Outsourcing of tasks",
    description:
      "Our chatbot development company  always stays ahead of its competitors as we are focused on increasing customer engagement.",
  },
  {
    image: ImageNine,
    title: "Cloud Computing",
    description:
      "We can provide cloud computing like Microsoft Azure through our unrivalled AI developers as it is difficult to save everyday data manually in its physical form.",
  },
];

function Features() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5 mb-5">
          <h1
            className="Text-heading text-center "
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            AI Notable Features
          </h1>
          <p
            className="text-detail mt-2 text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Our AI developers can use robotic process automation and AI to
            create features that help organisations optimise their expenditure,
            reduce effort and increase productivity.
          </p>
        </div>
        <div className="row d-flex justify-content-between mobile-responsive ">
          {data.map((data) => {
            return (
              <>
                <div
                  className="col-lg-3 col-md-5 col-sm-12 border-gradient border-gradient-purple mt-3 mb-3"
                  data-aos="flip-down"
                  data-aos-duration="1000"
                >
                  <div className="row justify-content-center ">
                    <div className="col-md-10 mt-3 mb-3">
                      <div className="text-center">
                        <img
                          src={data.image}
                          className="img-fluid"
                          width={"120px"}
                          alt=""
                        />
                      </div>
                      <div className="mt-3">
                        <h6 className=" text-white text-title mb-3">
                          <strong>{data.title}</strong>
                        </h6>
                        <p className="mt-2 text-description">
                          {data.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Features;
