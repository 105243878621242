import React from "react";
import Ai from "./ai";
import PremiumAi from "./premiumAi";
import SmartJourney from "./smartJourney";
import AiDevvelopment from "./aiDevvelopment";
import Leverage from "./leverage";
import ObjectDetection from "./objectDetection";
import Pose from "./pose";
import Roots from "./roots";
import Productive from "./productive";
import Features from "./features";
import FrequentlyAsked from "./frequentlyAsked";

function Index() {
  return (
    <>
      <Ai />
      <PremiumAi />
      <SmartJourney />
      <AiDevvelopment />  
      <Leverage />
      <ObjectDetection />
      <Pose />  
      <Roots />
      <Productive />
      <Features />
      <FrequentlyAsked />
    </>
  );
}

export default Index;
