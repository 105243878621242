import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Line from "../../assets/Image/UnderLine-01.svg";

const accordionData = [
  {
    key: 3,
    title: "What is the size of the AI industry market?",
    detail:
      "The AI market is predicted to expand from $21.46 billion in 2018 to $190.61 billion by the end of 2025.",
  },
  {
    key: 4,
    title: "Are there any difficulties with AI?",
    detail:
      "Only a few such as trust deficits and data scarcity are easily handled by a reputable AI development company like Virtual Tech.",
  },
];
const accordionDataTwo = [
  {
    title: "What are the advantages of using AI?",
    key: 1,
    detail:
      "The advantages include customer interaction automation, data mining, real-time help, rapid innovation and many more.",
  },
  {
    key: 2,
    title: "What is the purpose of AI? ",
    detail:
      "AI introduces innovative algorithms for data utilisation in order to provide organisations with digitalization and a competitive advantage.",
  },
];

function FrequentlyAsked() {
  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5">
          <h1 className="Text-heading text-center">
            Frequently Asked Questions
          </h1>
        </div>
        <div className="col-lg-5 col-md-12 mt-3">
          <div className="text-center">
            <img src={Line} className="img-fluid" alt="" width={"70%"} />
          </div>
        </div>
        <div className="row justify-content-between mt-5 mb-5" >
          <div className="col-lg-6 col-md-12 col-sm-12 ">
            {accordionData.map((data) => {
              return (
                <>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body>{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 ">
            {accordionDataTwo.map((data) => {
              return (
                <>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body>{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
}

export default FrequentlyAsked;
