import React from "react";
// import "./frequenltyAccordion.css";
import Line from "../../assets/Image/UnderLine-01.svg";
// import Line from "../../assets/Image/UnderLine-01.svg";

import Accordion from "react-bootstrap/Accordion";

function Frequentlyasked() {
  const accordionData = [
    {
      title: "How Much Does It Cost To Develop A Website?",
      key: 1,
      detail:
        "The cost of producing a web3 solution varies depending on the type of project and the needs. Only professionals can provide a cost estimate after considering the technology stack, project length and labour requirements.",
    },
    {
      key: 2,
      title: "What Technology Stack Is Used To Create Web3 Apps? ",
      detail:
        "Blockchain, AI and machine learning are used to create Web3 apps.",
    },
  ];
  const accordionDataTwo = [
    {
      key: 3,
      title: "How Much Time Does It Take to Create a Web3 Platform?",
      detail:
        "A Web3 platform can take 6 to 12 months or more to construct. The duration will be determined by the project specifications.",
    },
    // {
    //   key: 4,
    //   title: "Do you offer Web3 consultation services?",
    //   detail: "Do you offer Web3 consultation services?",
    // },
  ];
  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5" data-aos="fade-up">
          <h1 className="Text-heading text-center">
            Frequently Asked Questions
          </h1>
        </div>
        <div className="col-lg-5 col-md-12 mt-3">
          <div className="text-center">
            <img src={Line} className="img-fluid" alt="" width={"70%"} />
          </div>
        </div>
        <div className="row justify-content-between mt-5 mb-5">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {accordionData.map((data) => {
              return (
                <>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body>{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 ">
            {accordionDataTwo.map((data) => {
              return (
                <>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body>{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frequentlyasked;
