import React from "react";
import About from "./about";
import Celebrate from "./celebrate";
import OurFounders from "./ourFounders";

function AboutUsComp() {
  return (
    <>
      <About />
      <Celebrate />
      <OurFounders />
    </>
  );
}

export default AboutUsComp;
