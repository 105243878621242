import React from "react";
import Contact from "./contact";
import ContactNo from "./contactNo";
import "./contact.css";

function Index() {
  return (
    <>
      <Contact />
      <ContactNo />
    </>
  );
}

export default Index;
