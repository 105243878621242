import React from "react";
const data = [
  {
    no: "20+",
    title: "Metaverse Projects",
  },
  {
    no: "50+",
    title: "Metaverse Developers",
  },
  {
    no: "2+",
    title: "year experience",
  },
  {
    no: "70+",
    title: "Digital Solutions",
  },
];

function MetaverseProject() {
  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-evenly align-items-center">
        {data.map((data) => {
          return (
            <>
              <div
                className="col-lg-2 col-md-3 col-sm-12 card-metaverse mt-3"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="mt-3 mb-3">
                  <div className="text-center">
                    <h1 className="text-title">
                      <b>{data.no}</b>
                    </h1>
                    <h2 className="text-metaverse ">{data.title}</h2>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default MetaverseProject;
