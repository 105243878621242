import React from "react";
import "./metaverse.css";
function LeverageTop() {
  return (
    <div className="Leverage-bg ">
      <div className="container">
        <div className="row justify-content-center align-items-center  mt-5 mb-5">
          <div className="col-lg-10 col-md-12 col-sm-12 mt-5 mb-5">
            <h1
              className="Text-heading text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              World Leading Metaverse Unlockers
            </h1>
            <p
              className="text-detail text-center mt-4"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Leverage the Virtual Revolution with Virtual Tech. Your
              trailblazing partner in Metaverse Development across the world. Our
              commitment to speed, simplicity and cost-efficiency accelerates
              your journey towards manifesting your Metaverse dreams, outpacing
              the competition.
            </p>
            <div
              className="text-center mt-3"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <button className="btn btn-experts p-3">Contact Us Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LeverageTop;
