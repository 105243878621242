import React from "react";
const data = [
  {
    no: "01",
    title: "Planning",
    description:
      "Every step must be meticulously planned from knowing the client requirements to implementing them into the design.",
  },
  {
    no: "02",
    title: "Designing",
    description:
      " After the study is completed the process of software design takes over to describe the overall architecture.",
  },
  {
    no: "03",
    title: "Defining",
    description:
      "After a detailed description and documentation of the software requirements the client consent is obtained.",
  },
  {
    no: "04",
    title: "Building",
    description:
      "The creation and execution of computer code mark the start of software development.",
  },
  {
    no: "05",
    title: "Testing",
    description:
      "All mistakes are reported, repaired and tested until the final programme meets quality standards.",
  },
  {
    no: "06",
    title: "Deployment",
    description:
      "After multiple testings your Metaverse platform will be launched in the market with garmented support and maintenance even after the launch.",
  },
];

function Follow() {
  return (
    <div className="development-bg">
      <div className="container">
        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-lg-8 col-md-12 col-sm-12 mt-5">
            <h1
              className="Text-heading text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Custom Software Development Approach
            </h1>
            <p
              className="mt-2 text-detail text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              From inspiration to execution from design to development our
              finest software development company pays close attention to each
              stage of development. Take a look at our effective product
              development lifecycle in action.
            </p>
          </div>
          <div className="col-lg-12 col-md-12 mt-3">
            <div className="row justify-content-around">
              {data.map((data) => {
                return (
                  <>
                    <div
                      className="col-lg-5 col-md-10 col-sm-11 mt-3 mb-3"
                      data-aos="flip-left"
                      data-aos-duration="1000"
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-3 ">
                          <h1 className="text-center text-number">{data.no}</h1>
                        </div>
                        <div className="col-md-9">
                          <h1 className="top-heading">{data.title}</h1>
                          <p className="text-detail">{data.description}</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Follow;
