import React from "react";
const data = [
  {
    title: "Metaverse",
    detail:
      "We provide metaverse software development services that provide unique user experiences and a wide range of commercial options. Our unique software platform solutions incorporate a wide range of cutting-edge technologies, including blockchain, AR/VR, digital twins, 3D modelling, AI and IoT.",
  },
  {
    title: "Web3",
    detail:
      "We use our expertise in Rust, Solidity, Golang, and frameworks such as Substrate and Brownie to build next-generation dApps that enable web3 apps. Our web3 developers continue to create one-of-a-kind web3 dApps with an emphasis on scalability, security and interoperability.",
  },
  {
    title: "NFT",
    detail:
      "We develop and construct NFT markets to support live auctions and NFT trading in a realistic 3D environment. We also provide ready-to-deploy NFT marketplace solutions that can be customised and integrated with client Web3 applications for a quicker time-to-market.",
  },
];

function CustomSoftware() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-9 col-md-12 col-sm-12">
          <h1
            className="Text-heading text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Innovative Custom Software Solutions
          </h1>
          <p
            className="text-center text-detail mt-3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            We understand that building bespoke software entails a big financial
            investment from you and your organisation and that it should not be
            undertaken lightly. We provide bespoke software creation for various
            formats based on your specifications to guarantee that this is the
            right course of action for you.
          </p>
        </div>
        <div className="row justify-content-between  mobile-responsive ">
          {data.map((data) => {
            return (
              <>
                <div
                  className="col-lg-3 col-md-11 col-sm-12 border-gradient mt-5"
                  data-aos="flip-right"
                  data-aos-duration="1000"
                >
                  <div className="m-3">
                    <h1 className="text-title"><strong>
                      {data.title}
                      </strong>
                      </h1>
                    <p className=" text-detail">{data.detail}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CustomSoftware;
