import React from "react";

const data = [
  {
    no: "01",
    title: "Data Gathering",
    description:
      " Our Metaverse developers  will first understand your product requirements through heavy research and then begin your project.",
  },
  {
    no: "02",
    title: "UI/UX Design",
    description:
      " Our Metaverse developers   will create trendy designs to provide your clients with a user-friendly interface with the latest tech stack.",
  },
  {
    no: "03",
    title: "Prototype ",
    description:
      "Prototype After the design our skilled Metaverse developers  will develop an efficient prototype which will be forwarded for the next process.",
  },
  {
    no: "04",
    title: "Development",
    description:
      "Your platform will now be developed with complete transparency using cutting-edge technologies and sent further for testing.",
  },
  {
    no: "05",
    title: "Testing",
    description:
      "Our Metaverse developers  will now begin the quality assurance phase with multiple tests before the final launch.",
  },
  {
    no: "06",
    title: "Deployment",
    description:
      "After multiple testings your Metaverse platform will be launched in the market with garmented support and maintenance even after the launch.",
  },
];

function DevelopmentProccess() {
  return (
    <div className="development-bg">
      <div className="container mt-5">
        <div className="row justify-content-center align-items-center mt-5 mb-5">
          <div className="col-lg-10 col-md-12 col-sm-12 mt-5">
            <h1
              className="Text-heading text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
             Metaverse Development Process
            </h1>
            <p
              className="text-center text-detail"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Our leading Metaverse developers follow a structured development
              process to create a market-leading Metaverse platform with
              complete transparency and cost-effectiveness.
            </p>
          </div>
          <div className="col-lg-12 col-md-12 mt-3">
            <div className="row justify-content-around">
              {data.map((data) => {
                return (
                  <>
                    <div
                      className="col-lg-5 col-md-10 col-sm-11 mt-3 mb-3"
                      data-aos="flip-left"
                      data-aos-duration="1000"
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-3 ">
                          <h1 className="text-center text-number">{data.no}</h1>
                        </div>
                        <div className="col-md-9">
                          <h1 className="top-heading">{data.title}</h1>
                          <p className="text-detail">{data.description}</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevelopmentProccess;
