import React, {useEffect} from "react";
import ContactComp from "../../component/contactUs/index";

function Contact() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <ContactComp />
    </>
  );
}

export default Contact;
