import React from "react";
import chatbot from "../../assets/Image/ChatBot.jpg";
import Models from "../models/model";
import { useState } from "react";

function NextGen() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="container ">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-10 col-md-12 col-sm-12 mt-5">
          <h1 className="Text-heading text-center" data-aos="fade-up">
            Next-Gen Tech-Driven Services
          </h1>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5">
          <div data-aos="fade-up">
            <img src={chatbot} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5">
          <p className="text-detail" data-aos="fade-up">
            With years of experience in AI development Virtual Tech leads the
            market with its top-notch chatbot development solutions loaded with
            all the necessary steps. From market research to the final launch
            and product marketing we provide an end-to-end solution for you.
            Our team has the calibre to accommodate all your requirements for
            the final product. Whether it's custom functionalities, custom
            design features or security layers. We make sure to build projects
            for you. Our team works with a client-centric approach which helps
            us get happy clients every time.
          </p>
          <div data-aos="fade-up">
            <button
              className="btn btn-experts p-3"
              onClick={() => setModalShow(true)}
            >
              Have A Consultation
            </button>
            <Models show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NextGen;
