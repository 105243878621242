import React, {useEffect} from "react";
import Web3Comp from "../../component/web3/index";

function Web3() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Web3Comp />
    </>
  );
}

export default Web3;
