import React from "react";
import Models from "../models/model";
import { useState } from "react";

function CustomSoftwareDevelopment() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="collaborate-bg mt-5">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-9 col-md-12 col-sm-12 mt-5 mb-5">
            <h1
              className="Text-heading text-center mt-5 mb-2"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Leave Custom Software Development to Us
            </h1>
            <p
              className="mt-2 text-detail text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Virtual Tech is delighted to contribute to your success story and
              accelerate your company progress with cutting-edge IT solutions.
            </p>
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <button
                className="btn btn-experts p-3"
                onClick={() => setModalShow(true)}
              >
                Schedule A Call
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomSoftwareDevelopment;
