import React from "react";
import Face from "../../assets/Image/faceDetection.jpg";
import Models from "../models/model";
import { useState } from "react";

function Pose() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="container">
      <div className="row align-items-center ">
        <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5 ">
          <h1
            className="Text-heading"
            style={{ fontSize: "15px" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Pose Evaluation
          </h1>
          <h4
            className="Text-heading"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Custom Face Recognition Seamless AI Development
          </h4>
          <p
            className="text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Our AI development services   can create this cutting-edge
            biometric technology to help organisations with security and
            personalised advertising.
          </p>
          <p
            className=" text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Custom face recognition may be used by businesses for user
            identification, biometric attendance systems, online profile
            verification, personalised automobile experiences and much more.
          </p>
          <div className="" data-aos="fade-up">
            <button
              className="btn btn-experts p-3"
              onClick={() => setModalShow(true)}
            >
              Contact Us
            </button>
            <Models show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5">
          <div data-aos="fade-up">
            <img src={Face} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pose;
