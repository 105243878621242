import React, {useEffect} from "react";
import ApplicationComp from "../../component/app/index";

function Application() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <ApplicationComp />
    </>
  );
}

export default Application;
