import React from "react";
import IOT from "../../assets/Image/Iot.jpg";
import Models from "../models/model";
import { useState } from "react";

function SuperCharge() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-10 col-md-12 col-sm-12 ">
          <h1
            className="Text-heading text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            IoT App Development Supercharge Innovation Era
          </h1>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5">
          <img src={IOT} className="img-fluid" alt="" />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 mt-5 mb-5">
          <p
            className="text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            A decade into revolutionising the IoT industry. Virtual Tech IoT app
            development services in world  are leading the charge. We're proud
            to help shape the future by providing unmatched IoT solutions that
            drive business success.
          </p>
          <p
            className="text-detail"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Unleash Potential with Our Expert IoT Developers:
            Initiate your project, post an in-depth analysis of your business
            objectives and let's collaborate to skyrocket your revenue. Your
            journey to success starts with Virtual technology.
          </p>
          <div className="" data-aos="fade-up" data-aos-duration="1000">
            <button
              className="btn btn-experts p-3"
              onClick={() => setModalShow(true)}
            >
              Contact Us Now
            </button>
            <Models show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperCharge;
