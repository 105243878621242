import React from "react";
import Metaverse from "./metaverse";
import "./metaverse.css";
import MetaverseProject from "./metaverseProject";
import NextGenMetaverse from "./nextGenMetaverse";
import LeverageTop from "./leverageTop";
import GameChanging from "./gameChanging";
import DevelopmentProccess from "./developmentProccess";
import ExpertDeveloper from "./expertDeveloper";
import MultiEngagement from "./multiEngagement";
import StrelingDevelopment from "./strelingDevelopment";
import UseCase from "./useCase";
import FrequentlyAsked from "./frequentlyAsked";

function Index() {
  return (
    <>
      <Metaverse />
      <MetaverseProject />
      <NextGenMetaverse />
      <LeverageTop />
      <GameChanging />
      <DevelopmentProccess />
      <ExpertDeveloper />
      <MultiEngagement />
      <StrelingDevelopment />
      <UseCase />
      <FrequentlyAsked />
      
    </>
  );
}

export default Index;
