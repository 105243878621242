import React from "react";
import Application from "./app";
import MobileApp from "./mobileApp";
import ChooseThe from "./chooseThe";
import Takeyour from "./takeyour";
import DubaiApp from "./dubaiApp";
import EngagementModel from "./engagementModel";
import MobileApplication from "./mobileApplication";
import NextGen from "./nextGen";
import BestMobile from "./bestMobile";
import FullTime from "./fullTime";
import FrequentlyAsked from "./frequentlyAsked";

function Index() {
  return (
    <>
      <Application />
      <MobileApp />
      <ChooseThe />
      <Takeyour />
      <DubaiApp />
      <EngagementModel />
      <MobileApplication />
      <NextGen />
      <BestMobile />
      <FullTime />
      <FrequentlyAsked />
    </>
  );
}

export default Index;
