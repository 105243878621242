import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Line from "../../assets/Image/UnderLine-01.svg";

const accordionData = [
  {
    title: "What is a chatbot and how does it work?",
    key: 1,
    detail:
      "A chatbot performs a variety of functions that are based on algorithms and simulate human discussions. In a web or mobile application a bot will be programmed to communicate with  human speech.",
  },
  {
    key: 2,
    title: "How much time does it take to create a chatbot? ",
    detail:
      "The time frame might be somewhere between 3 and 12 months depending on the adjustments.",
  },
];
const accordionDataTwo = [
 
  {
    key: 3,
    title: "How can your chatbot solutions benefit my company?",
    detail:
      "Increase customer interaction, lead creation, consumer data monitoring for insights and much more.",
  },
];
function FrequentlyAsked() {
  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-5">
          <h1 className="Text-heading text-center" data-aos="fade-up">
            Frequently Asked Questions
          </h1>
        </div>
        <div className="col-lg-5 col-md-12 mt-3">
          <div className="text-center">
            <img src={Line} className="img-fluid" alt="" width={"70%"} />
          </div>
        </div>
        <div className="row justify-content-between mt-5 mb-5">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {accordionData.map((data) => {
              return (
                <>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body>{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            {accordionDataTwo.map((data) => {
              return (
                <>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body>{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default FrequentlyAsked;
