import React from "react";
import Models from "../models/model";
import { useState } from "react";

function BestMobile() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="take-bg">
      <div className="container mt-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-8 col-md-11 col-sm-12 mt-5 mb-5">
            <h1 className="text-center Text-heading mt-3 " data-aos="fade-up">
              Premier Mobile Solutions Provider
            </h1>
            <p className="text-center text-detail" data-aos="fade-up">
              Allow technology to propel your company forward with our
              game-changing mobile application development services .
            </p>
            <div className="text-center" data-aos="fade-up">
              <button
                className="btn btn-experts p-3"
                onClick={() => setModalShow(true)}
              >
                Have a Free Consultation
              </button>
              <Models show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BestMobile;
