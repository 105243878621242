import React from "react";
// import ComingSoon from "../../assets/Coming Soon/comingsoon.png";
import Line from "../../assets/Image/UnderLine-01.svg";
import "./metaverse.css";

function Metaverse() {
  return (
    <div className="metaverse-bg">
      <div className="container">
        <div className="row justify-content-start top-margin">
          <div className="col-lg-5 col-sm-12 text-center mt-5 mb-5">
            <h1
              className="Text-heading"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Next-Gen Metaverse Solutions Embark Today
            </h1>
            <p className="text-detail text-left" data-aos="fade-up">
              Is a leading Metaverse Development Company that assists businesses
              in owning their own virtual space in the Metaverse? Our Virtual
              Tech Metaverse developers are seasoned Metaverse experts with 2+
              years of expertise in the Metaverse.
            </p>
            <div
              className="text-start mt-3 mb-5"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <button className="btn btn-experts p-3">
                Begin Your Journey
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Metaverse;
