import React from "react";
import Art from "../../assets/Icon/Ai-01.svg";
import Data from "../../assets/Icon/massiveData-01.svg";
import Argument from "../../assets/Icon/argument-01.svg";
import DataCloud from "../../assets/Icon/DataCloud-01.svg";

const data = [
  {
    icon: Art,
    title: "Artificial Intelligence",
    detail:
      "Implement AI-based techniques such as Deep Learning and Machine Learning with our seamless mobile app development services to produce a high-quality mobile app with smart features.",
  },
  {
    icon: Data,
    title: "Massive Data",
    detail:
      "Use our mobile app development services to create a scalable approach to growing your organisation with a dependable data analysis system.",
  },
  {
    icon: Argument,
    title: "Augmented Reality",
    detail:
      "Using AR enterprise training software design 3D prototypes and next-level mobile apps that work smoothly and help you save money.",
  },
  {
    icon: DataCloud,
    title: "The Cloud Computing",
    detail:
      "Our professionals leverage AWS cloud technologies and Microsoft Azure to build a flexible and connected business environment for our mobile app developers .",
  },
];

function MobileApplication() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-11 col-md-12 col-sm-12">
          <h1 className="text-center Text-heading" data-aos="fade-up">
            Innovative Mobile Solutions Competitive Advantage
          </h1>
          <p className="text-center text-detail mt-3" data-aos="fade-up">
            Virtual Tech Solutions collaborates with top-tier mobile app
            developers  who strive for originality and innovation
            while utilising the appropriate tech stack to create
            industry-leading mobile apps.
          </p>
        </div>
        <div className="row justify-content-around align-items-center">
          {data.map((data, index) => {
            return (
              <>
                <div
                  key={index}
                  className="col-lg-4 col-md-5 col-sm-12  border-card mt-5"
                  style={{ height: "300px" }}
                  data-aos="flip-right"
                  data-aos-delay="1000"
                >
                  <div className="m-3">
                    <div className="text-center mb-3">
                      <img
                        src={data.icon}
                        className="img-fluid"
                        alt=""
                        width={"100px"}
                      />
                    </div>
                    <h5 className=" text-title ">{data.title}</h5>
                    <p className=" text-detail">{data.detail}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MobileApplication;
