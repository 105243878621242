import React from "react";
import RD from "../../assets/Image/Ai.jpg";
import AI2 from "../../assets/Image/ai2.jpg";
import AI3 from "../../assets/Image/ai3.jpg";
import AI4 from "../../assets/Image/ai4.jpg";

const data = [
  {
    image: RD,
    title: "Discover R&D Projects",
    describtion:
      "AI is being researched and developed and when utilised correctly it has the potential to help organisations expand and provide new levels of consistency with increased decision-making and accuracy.",
  },
  {
    image: AI2,
    title: "Recognise a data-driven culture",
    describtion:
      "Redesign your AI strategy with the best AI development services and reorganise your workforce to support AI services based on ethical principles based on human-machine partnership.",
  },
  {
    image: AI3,
    title: "Locate appropriate use cases",
    describtion:
      "Before selecting your AI developers explore the possibilities of AI. AI is more than a nice-to-have for organisations it is a must-have. Find a use case that can more than double ROIs.",
  },
  {
    image: AI4,
    title: "Develop your business plan",
    describtion:
      "A good AI development company such as Virtual Tech will assist organisations in developing a master plan and understanding their company value via the use of numerous AI applications on strategic objectives. ",
  },
];

function SmartJourney() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5 mb-5">
        <div className="col-lg-10 col-md-12 col-sm-12">
          <h1 className="Text-heading text-center">
            Smart Journey with AI Developers
          </h1>
          <p className="text-detail mt-2 text-center">
            Businesses should be aware that AI may identify many holes in the
            industry and help provide effective services and quality products if
            they use seasoned AI development services .
          </p>
        </div>
        <div className="row justify-content-between align-items-center">
          {data.map((data) => {
            return (
              <>
                <div
                  class="col-lg-3 col-md-12 mt-4"
                  data-aos="flip-left"
                  data-aos-duration="1000"
                >
                  <div class="card  smart-card-responsive" style={{ height: "100%" }}>
                    <img
                      src={data.image}
                      class="card-img-top"
                      alt="..."
                      //   width={"80%"}
                    />
                    <div class="card-body">
                      <h3 class="card-title  mt-3">{data.title}</h3>

                      <p
                        class="card-text text-center mt-3 "
                        style={{ fontSize: "16px" }}
                      >
                        {data.describtion}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SmartJourney;
