import React from "react";
import fixed from "../../assets/Icon/teampriceidea-03.svg";
import renting from "../../assets/Icon/Renting-01.svg";
import solution from "../../assets/Icon/teampriceidea-01.svg";
const data = [
  {
    icon: fixed,
    title: "Fixed Budget",
    point1:
      "A fixed budget Metaverse development technique ensures paying a lump sum amount to experts for metaverse development.",
    point2: "Pay only for quality not quantity",
    point3: "Focused pricing policy",
    point4: "There is no add-on cost",
    point5: "High working standards",
  },
  {
    icon: renting,
    title: "Developers on Lease",
    point1:
      "Hire our skilled mobile app developers  on an hourly basis to complete your project quickly.",
    point2: "Every step of the way there is consultation",
    point3: "Pay by the hour",
    point4: "Regular project updates",
    point5: "Respect your time",
  },
  {
    icon: solution,
    title: "White label solution",
    point1:
      "We offer seamless metaverse development services with platforms created instantly to be launched on all app stores.",
    point2: "Tailor-made solution",
    point3: "Delivery before deadline",
    point4: "Cost-effective solutions",
    point5: "Real-time update",
  },
];

function EngagementModel() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-10 col-md-12 col-sm-12 mt-5 mb-5">
          <h1 className="text-center Text-heading" data-aos="fade-up">
            Diverse Mobile App Engagement Models
          </h1>
          <p className="text-center text-detail" data-aos="fade-up">
            Our mobile app developers have mastered the art of developing
            next-generation IT services for various enterprises. Stay one step
            ahead of the competition by partnering with the top mobile app
            development firm which will guide your company towards growth and
            progress. Let us now examine our business models.
          </p>
        </div>
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-12 col-md-12 mb-5">
            <div className="row ">
              {data.map((data, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="col-lg-4 col-md-12 col-sm-12 mt-4"
                      data-aos="flip-left"
                    >
                      <div className="card" style={{ height: "100%" }}>
                        <div className="text-center m-3">
                          <img
                            src={data.icon}
                            className="img-fluid"
                            alt=""
                            width={"90px"}
                          />
                        </div>
                        <div className="text-left m-4">
                          <h3 className="">{data.title}</h3>
                          <h6 className=" mt-4">{data.point1}</h6>
                          <ul className="mt-4">
                            <li>{data.point2}</li>
                            <hr />
                            <li>{data.point3}</li>
                            <hr />
                            <li>{data.point4}</li>
                            <hr />
                            <li>{data.point5}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EngagementModel;
